import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBrain,
    faKeyboard,
    faThumbsUp
} from "@fortawesome/free-solid-svg-icons";

const Hero3: React.FC = () => {
    return (
        <Container fluid className="home-page" style={{ paddingTop: "8rem" }}>
            <Container>
                <Row className="mb-5">
                    <Col md={6}>
                        <h1 className="display-4">Find Your Dream Job</h1>
                        <p className="lead">
                            Let our AI match you with the perfect job based on
                            your preferences.
                        </p>
                    </Col>
                    <Col md={6}>
                        <iframe
                            width="100%"
                            height="315"
                            src="https://www.youtube.com/embed/YOUR_VIDEO_ID"
                            title="Explanatory Video"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    </Col>
                </Row>
                <Row className="mb-5">
                    <Col md={4}>
                        <Card className="text-center">
                            <Card.Body>
                                <FontAwesomeIcon icon={faBrain} className="mb-3" />
                                <Card.Title>AI Matching</Card.Title>
                                <Card.Text>
                                    Our AI technology matches your profile with
                                    the best job opportunities.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card className="text-center">
                            <Card.Body>
                                <FontAwesomeIcon icon={faKeyboard} className="mb-3" />
                                <Card.Title>Natural Language Input</Card.Title>
                                <Card.Text>
                                    Describe your ideal job in your own words
                                    and let our AI do the rest.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card className="text-center">
                            <Card.Body>
                                <FontAwesomeIcon  icon={faThumbsUp} className="mb-3" />
                                <Card.Title>Custom Recommendations</Card.Title>
                                <Card.Text>
                                    Get personalized job recommendations
                                    tailored to your preferences.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center">
                        <Form>
                            <Form.Group controlId="formLocation">
                                <Form.Label>
                                    Enter Your Preferred Job Location
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="e.g., New York, San Francisco, Remote"
                                    className="w-50 mx-auto"
                                />
                            </Form.Group>
                            <Button
                                variant="primary"
                                type="submit"
                                className="mt-3"
                            >
                                Start Questionnaire
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
};

export default Hero3;
