import Container from "react-bootstrap/esm/Container";
import { Helmet } from "react-helmet";
import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
    const err = useRouteError() as Error;
    return (
        <Container>
            <Helmet>
                <title>Error | Next Jobs</title>
            </Helmet>
            <h4>Error!</h4>
            <p>{err.message}</p>
            <p>Please refresh the page.</p>
        </Container>
    );
}
