import { Container, Row, Col, Form, Button } from 'react-bootstrap';

const Hero1: React.FC = () => {
  return (
    <Container fluid className="hero-section" style={{ backgroundColor: '#333', color: '#fff', padding: '50px 0', paddingTop: "8rem" }}>
      <Container>
        <Row className="text-center">
          <Col>
            <h1 className="display-3">Unlock Your Dream Job</h1>
            <p className="lead">Let our AI match you with the perfect job based on your preferences.</p>
            <Button variant="warning" size="lg" className="mt-3" href="#start">
              Get Started
            </Button>
          </Col>
        </Row>
        <Row className="justify-content-center text-center mt-5">
          <Col md={10}>
            <iframe
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/YOUR_VIDEO_ID"
              title="Explanatory Video"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </Col>
        </Row>
        <Row className="justify-content-center text-center mt-5" id="start">
          <Col md={6}>
            <Form>
              <Form.Group controlId="formLocation">
                <Form.Label>Enter Your Preferred Job Location</Form.Label>
                <Form.Control type="text" placeholder="e.g., New York, San Francisco, Remote" />
              </Form.Group>
              <Button variant="warning" type="submit" className="mt-3">
                Start Questionnaire
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Hero1;

