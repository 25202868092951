const quickstarters = {
    talentsPlaceholder:
        "e.g. I'm a UX/UI designer focused on intuitive user experiences, user research, and prototyping. Past: mobile apps, web platforms.",
    talents: [
        {
            display: "Dynamic Marketing Specialist",
            content:
                "I'm a dynamic marketing specialist with expertise in digital campaigns and social media strategy. I am strategic, data-driven, and have excellent communication skills. I thrive in fast-paced environments and am adept at analyzing market trends. My previous roles include managing product launches and running successful ad campaigns."
        },
        {
            display: "Skilled IT Support Technician",
            content:
                "I'm a skilled IT support technician with a strong background in troubleshooting and system maintenance. I am tech-savvy, patient, and excel at problem-solving. I have excellent communication skills and a customer-focused approach. My past experience includes providing technical support in both corporate and remote settings."
        },
        {
            display: "Detail-Oriented Financial Analyst",
            content:
                "I'm a detail-oriented financial analyst with a solid foundation in data analysis and financial modeling. I am analytical, organized, and have a strong aptitude for numbers. I am also a critical thinker and a good communicator. In my previous roles, I have worked on investment analysis and budgeting for large corporations."
        },
        {
            display: "Innovative Data Scientist",
            content:
                "I'm an innovative data scientist with expertise in machine learning and big data analytics. I am analytical, methodical, and excel at extracting insights from large datasets. I have strong programming skills and experience in data visualization. In my previous roles, I have worked on predictive modeling and data-driven decision-making for various industries."
        },
        {
            display: "Strategic IT Project Manager",
            content:
                "I'm a strategic IT project manager with a proven track record of delivering complex technology projects on time and within budget. I am organized, proactive, and excel at stakeholder communication and risk management. I thrive in dynamic environments and have experience leading cross-functional teams. My previous projects include software implementation and IT infrastructure upgrades."
        }
    ],
    workEnvPlaceholder:
        "e.g., I enjoy working in a creative, dynamic setting with a casual tone. I value a positive attitude among colleagues and opportunities for professional development.",
    workEnv: [
        {
            display: "Fast-Paced Startup",
            content:
                "I thrive in a fast-paced startup where innovation and agility are key. I value a flat hierarchy, close-knit team dynamics, and the opportunity to take on diverse roles and responsibilities in a collaborative setting."
        },
        {
            display: "Structured Corporate",
            content:
                "I prefer a structured corporate environment with clear processes, well-defined roles, and career advancement opportunities. I value stability, professional growth, and a workplace that encourages ongoing learning and development."
        },
        {
            display: "Dynamic Creative Agency",
            content:
                "I enjoy working in a creative agency with a dynamic atmosphere and collaborative spirit. I value creativity, open communication, flexible hours, and the chance to work on a variety of innovative projects with a passionate team."
        },
        {
            display: "Remote-Friendly Company",
            content:
                "I prefer a remote-friendly company with flexible working hours and a strong emphasis on work-life balance. I value independence, clear communication, regular virtual team meetings, and the ability to work from different locations."
        },
        {
            display: "Mission-Driven Non-Profit",
            content:
                "I thrive in a mission-driven non-profit with a collaborative team and a strong sense of purpose. I value meaningful work, community impact, and a supportive environment that encourages empathy, dedication, and professional growth."
        }
    ],
    industriesPlaceholder:
        "e.g., I'm looking to transition into the healthcare industry, focusing on roles that leverage my project management skills. Additionally, I have an interest in education technology, as I believe in the power of tech to transform learning experiences.",
    industries: [
        {
            display: "Tech and Renewable Energy",
            content:
                "I'm interested in the tech industry, especially in roles like software development or AI. I'm also passionate about renewable energy and want to work on innovative solutions for a sustainable future."
        },
        {
            display: "Healthcare and EdTech",
            content:
                "I'm looking to transition into healthcare, focusing on project management roles. Additionally, I have a strong interest in education technology, aiming to improve learning through innovative tech solutions."
        },
        {
            display: "Creative Industries and Non-Profits",
            content:
                "I'm aiming for a career in the creative industries, such as marketing or graphic design. I'm also open to non-profit roles where I can leverage my skills to support social impact initiatives."
        },
        {
            display: "Finance and Product Management",
            content:
                "I'm pursuing opportunities in the finance industry or as a product manager. I'm also interested in healthcare and tech sectors, looking for roles that offer growth and innovation opportunities."
        },
        {
            display: "Media and Entertainment",
            content:
                "I'm looking for a career in the media and entertainment industry, focusing on roles in content creation or digital marketing. I'm passionate about storytelling and want to work in a dynamic, creative environment."
        }
    ]
};

export default quickstarters;
