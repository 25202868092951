import Container from "react-bootstrap/esm/Container";
import Button from "react-bootstrap/esm/Button";
import Config from "./config";
import {
    ActionFunction,
    LoaderFunction,
    redirect,
    useActionData,
    useLoaderData,
    useSubmit
} from "react-router-dom";
import { IdTokenResult } from "firebase/auth";
import { doc, DocumentReference, Firestore, getDoc } from "firebase/firestore";
import { ProfileData } from "./Profile";
import Card from "react-bootstrap/esm/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FormEventHandler, useEffect, useState } from "react";
import Form from "react-bootstrap/esm/Form";
import Spinner from "react-bootstrap/esm/Spinner";
import FormCheckLabel from "react-bootstrap/esm/FormCheckLabel";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import { RawTerms } from "./Terms";
import Modal from "react-bootstrap/esm/Modal";
import { RawPrivacyPolicy } from "./PrivacyPolicy";
import { useLogEvent } from "./FirebaseProvider";
import { Helmet } from "react-helmet";

export const getPaymentAction: (
    authIdTokenRes: IdTokenResult | undefined | null
) => ActionFunction =
    authIdTokenRes =>
    async ({ request }) => {
        if (!authIdTokenRes) {
            return undefined;
        }
        const searchParams = new URL(request.url).searchParams;
        const price = searchParams.get("price");
        const plan = searchParams.get("plan");
        if (!price || !plan) {
            return { message: "Unknown plan or price provided." };
        }
        try {
            const res = await fetch(
                `${Config.BACKEND_URL}/payment?plan=${plan}&price=${price}`,
                {
                    method: "POST",
                    headers: {
                        authorization: `Bearer ${authIdTokenRes.token}`
                    }
                }
            );
            if (!res.ok) {
                const errMsg = await res.text();
                throw new Error(`Server response ${res.status}: ${errMsg}`);
            }
            const checkoutUrl = await res.text();
            return redirect(checkoutUrl);
        } catch (e) {
            const err = e as Error;
            return { message: err.message };
        }
    };

export const getPaymentLoader: (
    authIdTokenRes: IdTokenResult | undefined | null,
    db: Firestore
) => LoaderFunction =
    (authIdTokenRes, db) =>
    async ({ request }) => {
        if (authIdTokenRes === undefined) {
            return "loading";
        }
        if (authIdTokenRes === null) {
            const redirectComponent = new URLSearchParams({
                redirect: request.url.split("/")[3]
            });
            return redirect(`/login?${redirectComponent}`);
        }
        if ((authIdTokenRes.claims.premiumEndDate as number) >= Date.now()) {
            const uid = authIdTokenRes.claims.sub!;
            const userRef = doc(db, "users", uid) as DocumentReference<
                ProfileData,
                ProfileData
            >;
            const userSnap = await getDoc(userRef);
            const user = userSnap.data();
            if (!user) {
                return { errMsg: "User not found in database" };
            }
            return {
                premiumEndDate: authIdTokenRes.claims.premiumEndDate,
                bookedPlan: user.plan
            };
        }

        const searchParams = new URL(request.url).searchParams;
        const plan = searchParams.get("plan");
        const price = searchParams.get("price");
        const cancelled = searchParams.get("cancelled");
        if (!plan || !price) {
            return {
                errMsg: "Unknown plan or price selected. Must be one of starter, pro or ultimate with a valid price ID."
            };
        }
        return { plan, price, cancelled };
    };

export default function Payment() {
    const loaderData = useLoaderData() as
        | "loading"
        | { premiumEndDate: number; bookedPlan: string }
        | {
              plan: "starter" | "pro" | "ultimate";
              price: string;
              cancelled?: boolean;
          }
        | { errMsg: string };
    const actionData = useActionData() as { message: string } | undefined;
    const submit = useSubmit();
    const [loading, setLoading] = useState(false);
    const [formValidated, setFormValidated] = useState(false);
    const [showTerms, setShowTerms] = useState(false);
    const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
    const logEvent = useLogEvent();

    const Head = () => {
        return (
            <Helmet>
                <title>Payment | Next Jobs</title>
                <meta
                    name="description"
                    content="Secure payment for premium features on Next Jobs."
                />
                <meta property="og:title" content="Payment | Next Jobs" />
                <meta
                    property="og:description"
                    content="Secure payment for premium features on Next Jobs."
                />
                <meta
                    property="og:url"
                    content="https://nextjobs.app/payment"
                />
                <meta property="og:type" content="website" />
                <meta
                    property="og:image"
                    content="https://nextjobs.app/logo256.svg"
                />
            </Helmet>
        );
    };

    useEffect(() => {
        if (
            loaderData !== "loading" &&
            "plan" in loaderData &&
            loaderData.cancelled
        ) {
            logEvent("payment_cancelled", {
                plan: loaderData.plan
            });
        }
    }, [loaderData, logEvent]);

    if (loaderData === "loading") {
        return (
            <Container style={{ paddingTop: "8em", minHeight: "93.5vh" }}>
                <Head />
                <p>Loading ...</p>
            </Container>
        );
    }

    if ("premiumEndDate" in loaderData) {
        return (
            <Container style={{ paddingTop: "8em", minHeight: "93.5vh" }}>
                <Head />
                <p>
                    Your{" "}
                    <b>
                        {loaderData.bookedPlan.at(0)?.toUpperCase() +
                            loaderData.bookedPlan.slice(1)}
                    </b>{" "}
                    plan is active until{" "}
                    <b>
                        {new Date(loaderData.premiumEndDate).toLocaleString()}
                    </b>
                    .
                </p>
                <p>
                    You can renew it or buy another plan when the current one
                    expires.
                </p>
            </Container>
        );
    }

    if ("plan" in loaderData && "price" in loaderData) {
        const onFormSubmit: FormEventHandler<HTMLFormElement> = e => {
            e.preventDefault();
            if (!e.currentTarget.checkValidity()) {
                if (!formValidated) {
                    setFormValidated(true);
                }
                logEvent("payment_tcs_pp_incomplete", {
                    plan: loaderData.plan
                });
                return;
            }
            logEvent("begin_checkout", {
                currency: "USD",
                value: Config.PRICE_VALUES[loaderData.plan],
                items: [
                    {
                        item_id: Config.PRICES[loaderData.plan],
                        item_name: loaderData.plan,
                        price: Config.PRICE_VALUES[loaderData.plan],
                        quantity: 1
                    }
                ]
            });
            setLoading(true);
            submit(null, {
                method: "post",
                action: `/payment?plan=${loaderData.plan}&price=${loaderData.price}`
            });
        };

        return (
            <Container style={{ paddingTop: "8em", minHeight: "93.5vh" }}>
                <Head />
                <Card
                    className="text-center mx-auto shadow-sm"
                    style={{ width: "fit-content" }}
                >
                    <Card.Header as="h5">
                        {loaderData.plan.at(0)?.toUpperCase() +
                            loaderData.plan.slice(1)}{" "}
                        plan – ${Config.PRICE_VALUES[loaderData.plan]}
                    </Card.Header>
                    <Card.Body className="py-4">
                        <Card.Title>
                            Enjoy {Config.DAYS[loaderData.plan]} days of premium
                            features
                        </Card.Title>
                        <Card.Text>
                            Continue below for secure payment.
                        </Card.Text>
                    </Card.Body>
                    <Card.Footer className="text-muted">
                        <Form
                            id="checkout_initiating_form"
                            noValidate
                            validated={formValidated}
                            onSubmit={onFormSubmit}
                        >
                            <Form.Check
                                id="tcsCheck"
                                required
                                className="text-start"
                            >
                                <FormCheckInput type="checkbox" required />
                                <FormCheckLabel>
                                    I agree to the{" "}
                                    <Button
                                        variant="link"
                                        className="p-0 mb-1"
                                        onClick={() => {
                                            logEvent("terms_open", {
                                                plan: loaderData.plan
                                            });
                                            setShowTerms(true);
                                        }}
                                    >
                                        terms and conditions
                                    </Button>
                                    *
                                </FormCheckLabel>
                                <Form.Control.Feedback type="invalid">
                                    Agreement required
                                </Form.Control.Feedback>
                            </Form.Check>
                            <Form.Check
                                id="ppCheck"
                                required
                                className="text-start"
                            >
                                <FormCheckInput type="checkbox" required />
                                <FormCheckLabel>
                                    I agree to the{" "}
                                    <Button
                                        variant="link"
                                        className="p-0 mb-1"
                                        onClick={() => {
                                            logEvent("privacy_policy_open", {
                                                plan: loaderData.plan
                                            });
                                            setShowPrivacyPolicy(true);
                                        }}
                                    >
                                        privacy policy
                                    </Button>
                                    *
                                </FormCheckLabel>
                                <Form.Control.Feedback type="invalid">
                                    Agreement required
                                </Form.Control.Feedback>
                            </Form.Check>
                            <Button
                                disabled={loading}
                                variant="primary"
                                className="w-100 mt-2"
                                type="submit"
                            >
                                {loading ? (
                                    <Spinner size="sm" />
                                ) : (
                                    <>
                                        <FontAwesomeIcon
                                            icon={faLock}
                                            className="me-2"
                                        />
                                        Pay with Stripe
                                    </>
                                )}
                            </Button>
                            {actionData && (
                                <p className="text-danger">
                                    {actionData.message}
                                </p>
                            )}
                        </Form>
                    </Card.Footer>
                </Card>
                <Modal
                    size="lg"
                    show={showTerms}
                    onHide={() => setShowTerms(false)}
                >
                    <Modal.Header closeButton />
                    <Modal.Body>
                        <RawTerms />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            onClick={() => setShowTerms(false)}
                            variant="secondary"
                        >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    size="lg"
                    show={showPrivacyPolicy}
                    onHide={() => setShowPrivacyPolicy(false)}
                >
                    <Modal.Header closeButton />
                    <Modal.Body>
                        <RawPrivacyPolicy />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            onClick={() => setShowPrivacyPolicy(false)}
                            variant="secondary"
                        >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container>
        );
    }

    return (
        <Container style={{ paddingTop: "8em", minHeight: "93.5vh" }}>
            <Head />
            <p className="text-danger">{loaderData.errMsg}</p>
        </Container>
    );
}
