import { useEffect, useRef, useState } from "react";
import FormControl, { FormControlProps } from "react-bootstrap/esm/FormControl";
import Overlay from "react-bootstrap/esm/Overlay";
import ListGroup from "react-bootstrap/esm/ListGroup";
import {
    doc,
    DocumentReference,
    getDoc,
    getFirestore
} from "firebase/firestore";
import { useLogEvent } from "./FirebaseProvider";

interface ConfigData {
    cities: string[];
}

export default function LocationDropdown(
    props: FormControlProps & {
        name: string;
        deploymentComponent?: string;
        onChange?: () => void;
    }
) {
    const [inputValue, setInputValue] = useState(
        (props.defaultValue as string | undefined) || ""
    );
    const [showMenu, setShowMenu] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const [locations, setLocations] = useState<string[]>([]);
    const [errMsg, setError] = useState<string>("");
    const db = getFirestore();
    const logEvent = useLogEvent();
    const propsCopy = { ...props };
    const deploymentComponent = props.deploymentComponent;
    delete propsCopy.deploymentComponent;
    delete propsCopy.defaultValue;

    useEffect(() => {
        const loadLocations = async () => {
            const configRef = doc(
                db,
                "config",
                "locations"
            ) as DocumentReference<ConfigData, ConfigData>;
            const configSnap = await getDoc(configRef);
            const configData = configSnap.data();
            if (!configData) {
                setError("Config data not found, locations not loaded.");
                return;
            }
            setLocations(configData.cities);
        };
        loadLocations();
    }, [db]);

    const locationSelectHandler = (location: string) => {
        logEvent("location_select", {
            location,
            deployment_component: deploymentComponent
        });
        setInputValue(location);
        props.onChange && props.onChange();
        inputRef.current?.focus();
        setShowMenu(false);
    };

    return (
        <>
            <FormControl
                ref={inputRef}
                {...propsCopy}
                value={inputValue}
                onClick={() => setShowMenu(true)}
                onBlur={() => setTimeout(() => setShowMenu(false), 200)}
                onChange={e => {
                    setInputValue(e.target.value);
                    setShowMenu(true);
                    props.onChange && props.onChange();
                }}
            />
            {errMsg && <p className="text-danger">{errMsg}</p>}
            <Overlay
                placement="bottom-start"
                target={inputRef}
                show={showMenu}
                transition={false}
                popperConfig={{
                    modifiers: [{ name: "offset", options: { offset: [0, 4] } }]
                }}
            >
                <ListGroup
                    style={{
                        width: inputRef.current?.offsetWidth,
                        overflowY: "scroll",
                        maxHeight: "200px"
                    }}
                    className="shadow"
                >
                    {locations
                        .filter(loc =>
                            loc.toLowerCase().includes(inputValue.toLowerCase())
                        )
                        .map((location, i, locs) => (
                            <ListGroup.Item
                                action
                                key={location}
                                eventKey={location}
                                className={
                                    i < locs.length - 1 ? "border-bottom-0" : ""
                                }
                                onClick={() => locationSelectHandler(location)}
                            >
                                {location}
                            </ListGroup.Item>
                        ))}
                </ListGroup>
            </Overlay>
        </>
    );
}
