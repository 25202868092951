import Stack from "react-bootstrap/esm/Stack";
import { Outlet, ScrollRestoration } from "react-router-dom";
import HeaderView from "./Header";
import FeedbackPopup from "./FeedbackForm";

export default function App() {
    return (
        <Stack className="bg-body-secondary">
            <HeaderView />
            <div>
                <Outlet />
            </div>
            <span className="text-center text-secondary py-4">
                (c) 2024 Next Jobs
            </span>
            <FeedbackPopup />
            <ScrollRestoration />
        </Stack>
    );
}
