import Form from "react-bootstrap/esm/Form";
import Button from "react-bootstrap/esm/Button";
import Fade from "react-bootstrap/esm/Fade";
import ProgressBar from "react-bootstrap/esm/ProgressBar";
import { useSubmit } from "react-router-dom";
import {
    FormEventHandler,
    useState,
    useRef,
    useEffect,
    ChangeEventHandler
} from "react";
import Spinner from "react-bootstrap/esm/Spinner";
import Stack from "react-bootstrap/esm/Stack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFire, faMagicWandSparkles } from "@fortawesome/free-solid-svg-icons";
import quickstarters from "./quickstarters";
import { Timestamp } from "firebase/firestore";
import { useLogEvent } from "./FirebaseProvider";
import Config from "./config";
import OverlayTrigger from "react-bootstrap/esm/OverlayTrigger";
import Tooltip from "react-bootstrap/esm/Tooltip";
import LocationDropdown from "./LocationDropdown";

export interface SearchData {
    location?: string;
    talents?: string;
    workEnv?: string;
    industries?: string;
    maxSimilarity?: number;
    hiddenJobs?: number;
    jobsFound?: number;
}

// Saved in Firestore
export type SavedSearchData = SearchData & { searchedAt: Timestamp };

export default function SearchWizard() {
    const [stage, setStage] = useState(1);
    const submit = useSubmit();

    const handleSubmit: FormEventHandler<HTMLFormElement> = async e => {
        e.preventDefault();
        setStage(stage + 1);
        if (stage === 4) {
            submit(e.currentTarget, { method: "post", action: "/search" });
        }
    };

    return (
        <Stack
            className="rounded-4 shadow text-center align-items-center px-2 my-5 my-md-4 my-xxl-5"
            style={{
                background:
                    "linear-gradient(129deg, hsl(233.81deg 91.06% 84.04%), rgb(255, 163, 197))",
                padding: "2rem 0",
            }}
        >
            <Fade in={stage > 1}>
                <ProgressBar
                    now={Math.ceil((stage - 1) * 25)}
                    className="w-50 mb-5 shadow-sm"
                />
            </Fade>
            <Form
                id="search_wizard_form"
                onSubmit={handleSubmit}
                autoComplete="off"
                style={{maxWidth:"500px"}}
            >
                <Fade in={stage === 1}>
                    <Form.Group hidden={stage !== 1}>
                        <Form.Label className="display-6 form-label mb-2">
                            Job Location
                        </Form.Label>
                        <p className="mb-5 fs-6 fw-light">
                            Where do you want to work from?
                        </p>
                        <LocationDropdown
                            placeholder="e.g., New York, Berlin, Remote"
                            type="text"
                            name="location"
                            className="mx-auto mb-5"
                            deploymentComponent="search_wizard"
                        />
                        <Button
                            className="shadow-sm"
                            onClick={() => setStage(stage + 1)}
                        >
                            Next
                        </Button>
                    </Form.Group>
                </Fade>
                <Fade in={stage === 2}>
                    <Form.Group hidden={stage !== 2}>
                        <WizardFormControl
                            name="talents"
                            label="Your Talents and Skills"
                            subtitle="What are you good at? What do you enjoy doing?"
                            placeholder={quickstarters.talentsPlaceholder}
                            focusInput={stage === 2}
                            quickstarters={quickstarters.talents}
                        />
                        <Button
                            variant="outline-secondary"
                            onClick={() => setStage(stage - 1)}
                            className="me-2 shadow-sm"
                        >
                            Back
                        </Button>
                        <Button
                            onClick={() => setStage(stage + 1)}
                            className="shadow-sm"
                        >
                            Next
                        </Button>
                    </Form.Group>
                </Fade>
                <Fade in={stage === 3}>
                    <Form.Group hidden={stage !== 3}>
                        <WizardFormControl
                            name="workEnv"
                            label="Work Environment"
                            subtitle="How would your dream job be if you could choose?"
                            placeholder={quickstarters.workEnvPlaceholder}
                            focusInput={stage === 3}
                            quickstarters={quickstarters.workEnv}
                        />
                        <Button
                            variant="outline-secondary"
                            onClick={() => setStage(stage - 1)}
                            className="me-2 shadow-sm"
                        >
                            Back
                        </Button>
                        <Button
                            onClick={() => setStage(stage + 1)}
                            className="shadow-sm"
                        >
                            Next
                        </Button>
                    </Form.Group>
                </Fade>
                <Fade in={stage === 4}>
                    <Form.Group hidden={stage !== 4}>
                        <WizardFormControl
                            name="industries"
                            label="Industries and Businesses"
                            subtitle="What industries or professional areas have you always wanted to work in?"
                            placeholder={quickstarters.industriesPlaceholder}
                            focusInput={stage === 4}
                            quickstarters={quickstarters.industries}
                        />
                        <Button
                            variant="outline-secondary"
                            onClick={() => setStage(stage - 1)}
                            className="me-2 shadow-sm"
                        >
                            Back
                        </Button>
                        <Button type="submit" className="shadow-sm">
                            See Results
                            <FontAwesomeIcon icon={faFire} className="ms-2" />
                        </Button>
                    </Form.Group>
                </Fade>
                <Fade in={stage === 5}>
                    <Stack hidden={stage !== 5}>
                        <h4>Sit back and relax ...</h4>
                        <span>
                            We're getting those results for you in no time!
                        </span>
                        <Spinner className="mx-auto mt-4" />
                    </Stack>
                </Fade>
            </Form>
        </Stack>
    );
}

function WizardFormControl(props: {
    label: string;
    name: string;
    subtitle: string;
    placeholder: string;
    focusInput: boolean;
    quickstarters: { display: string; content: string }[];
}) {
    const inputRef = useRef<HTMLTextAreaElement | null>(null);
    const [contentValue, setContentValue] = useState("");
    const [quickstartSelected, setQuickstartSelected] = useState(false);
    const [aiImproveClicked, setAiImproveClicked] = useState(false);
    const [improvementLoading, setImprovementLoading] = useState(false);
    const [errMsg, setErrMsg] = useState("");
    const logEvent = useLogEvent();

    useEffect(() => {
        if (props.focusInput) {
            inputRef.current?.focus();
        }
    }, [props.focusInput]);

    const controlChangeHandler: ChangeEventHandler<HTMLInputElement> = e => {
        if (quickstartSelected && contentValue.length > 0) {
            logEvent("quickstart_suggestion_edit", {
                name: props.name,
                prompt: contentValue
            });
        }
        if (aiImproveClicked) {
            logEvent("ai_improvement_edit", {
                name: props.name,
                prompt: contentValue
            });
        }
        setQuickstartSelected(false);
        setAiImproveClicked(false);
        setContentValue(e.target.value);
    };

    const quickstartsSelectHandler: ChangeEventHandler<
        HTMLSelectElement
    > = e => {
        logEvent("quickstart_select", {
            name: props.name,
            prompt: e.target.value
        });
        setQuickstartSelected(true);
        setAiImproveClicked(false);
        setContentValue(
            e.target.value === "Select example" ? "" : e.target.value
        );
    };

    const aiImprovedHandler = async () => {
        logEvent("ai_improve_click", {
            name: props.name,
            prompt: contentValue
        });
        setQuickstartSelected(false);
        setAiImproveClicked(true);
        setImprovementLoading(true);
        try {
            const refinementRes = await fetch(`${Config.BACKEND_URL}/refine`, {
                method: "POST",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify({
                    content: contentValue,
                    type: props.name
                })
            });
            if (!refinementRes.ok) {
                const msg = await refinementRes.text();
                throw new Error(msg || refinementRes.statusText);
            }
            const refinedContent = await refinementRes.text();
            if (refinedContent.length === 0) {
                throw new Error("No improvement found. Please try again.");
            }
            setContentValue(refinedContent);
        } catch (e) {
            const err = e as Error;
            setErrMsg(err.message);
            setTimeout(() => setErrMsg(""), 5000);
        }
        setImprovementLoading(false);
    };

    return (
        <>
            <Form.Label className="display-6 mb-2">{props.label}</Form.Label>
            <p className="mb-5 fs-6 fw-light">{props.subtitle}</p>
            <Form.Control
                as="textarea"
                ref={inputRef}
                rows={4}
                placeholder={props.placeholder}
                name={props.name}
                className="mb-2"
                value={contentValue}
                onChange={controlChangeHandler}
            />
            <Stack
                direction="horizontal"
                className="justify-content-between mb-5 align-items-center"
            >
                <OverlayTrigger
                    overlay={
                        <Tooltip>
                            Improve text with AI
                            {contentValue.length === 0 && (
                                <span>
                                    <br />
                                    (start writing something)
                                </span>
                            )}
                        </Tooltip>
                    }
                    placement="left"
                    delay={{ show: 250, hide: 200 }}
                >
                    <div>
                        <Button
                            size="sm"
                            variant="success"
                            className="top-0 start-100 shadow-sm"
                            onClick={aiImprovedHandler}
                            disabled={
                                improvementLoading || contentValue.length === 0
                            }
                        >
                            {improvementLoading ? (
                                <Spinner size="sm" />
                            ) : (
                                <span>
                                    Improve
                                    <FontAwesomeIcon
                                        icon={faMagicWandSparkles}
                                        className="ms-2"
                                    />
                                </span>
                            )}
                        </Button>
                    </div>
                </OverlayTrigger>
                <div className="d-flex justify-content-end flex-column flex-md-row align-items-end align-items-md-center">
                    <Form.Label className="mb-2 mb-md-0 me-md-3 mb-0 text-muted">
                        Fancy some inspiration?
                    </Form.Label>
                    <Form.Select
                        style={{ minWidth: "150px" }}
                        onChange={quickstartsSelectHandler}
                        size="sm"
                        className="w-25"
                    >
                        <option key="initial" value="Select example">
                            Select example
                        </option>
                        {props.quickstarters.map((qs, i) => (
                            <option key={i} value={qs.content}>
                                {qs.display}
                            </option>
                        ))}
                    </Form.Select>
                </div>
            </Stack>
            {errMsg && <p className="text-danger">{errMsg}</p>}
        </>
    );
}
