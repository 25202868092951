import { faFaceSmile } from "@fortawesome/free-regular-svg-icons";
import { faBullhorn, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    CollectionReference,
    addDoc,
    collection,
    Firestore,
    Timestamp
} from "firebase/firestore";
import {
    useState,
    FormEventHandler,
    Dispatch,
    SetStateAction,
    useRef,
    CSSProperties
} from "react";
import Button from "react-bootstrap/esm/Button";
import Card from "react-bootstrap/esm/Card";
import Form from "react-bootstrap/esm/Form";
import FormCheck from "react-bootstrap/esm/FormCheck";
import Overlay, { OverlayInjectedProps } from "react-bootstrap/esm/Overlay";
import Spinner from "react-bootstrap/esm/Spinner";
import Stack from "react-bootstrap/esm/Stack";
import { useFirestore, useLogEvent } from "./FirebaseProvider";

export default function FeedbackPopup(props: {
    margin?: string;
    style?: CSSProperties;
}) {
    const [formValidated, setFormValidated] = useState(false);
    const db = useFirestore();
    const [errMsg, setErrMsg] = useState("");
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [showFeedback, setShowFeedback] = useState(false);
    const triggerRef = useRef<HTMLButtonElement>(null);
    const logEvent = useLogEvent();

    const feedbackButtonHandler = () => {
        if (!showFeedback) {
            logEvent("feedback_open", {});
        }
        setShowFeedback(!showFeedback);
    };

    const feedbackCloseHandler = () => {
        logEvent("feedback_close", {});
        setShowFeedback(false);
    };

    return (
        <>
            <Button
                variant="success"
                className={`position-fixed bottom-0 end-0 shadow ${props.margin || "m-4"}`}
                style={props.style}
                onClick={feedbackButtonHandler}
                ref={triggerRef}
            >
                <FontAwesomeIcon icon={faBullhorn} className="me-2" />
                Feedback or Help
            </Button>
            <Overlay
                placement="top-end"
                target={triggerRef.current}
                show={showFeedback}
                popperConfig={{
                    modifiers: [{ name: "offset", options: { offset: [0, 7] } }]
                }}
            >
                {injProps =>
                    FeedbackForm({
                        injProps,
                        formValidated,
                        setFormValidated,
                        db,
                        errMsg,
                        setErrMsg,
                        loading,
                        setLoading,
                        success,
                        setSuccess,
                        setShow: setShowFeedback,
                        feedbackCloseHandler
                    })
                }
            </Overlay>
        </>
    );
}

interface FeedbackData {
    feedback?: FormDataEntryValue;
    submittedAt: Timestamp;
    email?: FormDataEntryValue;
    name?: FormDataEntryValue;
    contactAgreed: boolean;
}

function FeedbackForm(props: {
    injProps: OverlayInjectedProps;
    formValidated: boolean;
    setFormValidated: Dispatch<SetStateAction<boolean>>;
    db: Firestore;
    errMsg: string;
    setErrMsg: Dispatch<SetStateAction<string>>;
    loading: boolean;
    setLoading: Dispatch<SetStateAction<boolean>>;
    success: boolean;
    setSuccess: Dispatch<SetStateAction<boolean>>;
    setShow: Dispatch<SetStateAction<boolean>>;
    feedbackCloseHandler?: () => void;
}) {
    const submitHandler: FormEventHandler<HTMLFormElement> = async e => {
        props.setErrMsg("");
        props.setLoading(true);
        e.preventDefault();
        props.setFormValidated(true);
        if (!e.currentTarget.checkValidity()) {
            props.setLoading(false);
            return;
        }
        const formData = new FormData(e.currentTarget);
        const data = Object.fromEntries(formData);
        try {
            await addDoc(
                collection(props.db, "feedback") as CollectionReference<
                    FeedbackData,
                    FeedbackData
                >,
                {
                    ...data,
                    contactAgreed: data.contactAgreed === "on",
                    submittedAt: Timestamp.now()
                }
            );
        } catch (e) {
            const err = e as Error;
            props.setErrMsg(err.message);
            props.setLoading(false);
            return;
        }
        props.setLoading(false);
        props.setSuccess(true);
        setTimeout(() => {
            props.setShow(false);
        }, 2500);
    };

    return (
        <Card {...props.injProps} className="shadow">
            <Card.Header>
                <Stack
                    direction="horizontal"
                    className="justify-content-between"
                >
                    {props.success ? (
                        <span> Success!</span>
                    ) : (
                        <span>Have feedback or need help?</span>
                    )}
                    <Button variant="outline" className="px-1 py-0">
                        <FontAwesomeIcon
                            onClick={props.feedbackCloseHandler}
                            icon={faXmark}
                        />
                    </Button>
                </Stack>
            </Card.Header>
            <Card.Body>
                {props.success ? (
                    <span className="text-success">
                        Thanks for your feedback!
                        <FontAwesomeIcon icon={faFaceSmile} className="ms-2" />
                    </span>
                ) : (
                    <Form
                        id="feedback_form"
                        noValidate
                        validated={props.formValidated}
                        onSubmit={submitHandler}
                        className="bg-white"
                    >
                        <Stack gap={2}>
                            <Form.Group>
                                <Form.Control
                                    required
                                    placeholder="Write your message"
                                    name="feedback"
                                    as="textarea"
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide your message
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Control name="name" placeholder="Name" />
                            <Form.Control
                                placeholder="Email"
                                name="email"
                                type="email"
                            />
                            <FormCheck
                                id="contactAgreed"
                                name="contactAgreed"
                                type="checkbox"
                                label="You can contact me about this feedback"
                            />
                            {props.errMsg && (
                                <p className="text-danger">{props.errMsg}</p>
                            )}
                            <Button type="submit" disabled={props.loading}>
                                {props.loading ? (
                                    <Spinner size="sm" />
                                ) : (
                                    "Submit"
                                )}
                            </Button>
                        </Stack>
                    </Form>
                )}
            </Card.Body>
        </Card>
    );
}
