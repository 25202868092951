import { createContext, Dispatch, useContext, useReducer } from "react";

export interface ApplicationState {
    location?: string;
    talents?: string;
    workEnv?: string;
    industries?: string;
}

type ApplicationStateAction =
    | {
          type:
              | "SET_LOCATION"
              | "SET_TALENTS"
              | "SET_WORK_ENV"
              | "SET_INDUSTRIES";
          payload: string;
      }
    | {
          type: "SET_ALL";
          payload: ApplicationState;
      };

function applicationStateReducer(
    state: ApplicationState,
    action: ApplicationStateAction
) {
    switch (action.type) {
        case "SET_LOCATION":
            return { ...state, location: action.payload };
        case "SET_TALENTS":
            return { ...state, talents: action.payload };
        case "SET_WORK_ENV":
            return { ...state, workEnv: action.payload };
        case "SET_INDUSTRIES":
            return { ...state, industries: action.payload };
        case "SET_ALL":
            return action.payload;
        default:
            return state;
    }
}

const initialState: ApplicationState = {
    location: "",
    talents: "",
    workEnv: "",
    industries: ""
};

const ApplicationStateContext = createContext<{
    state: ApplicationState;
    dispatch: Dispatch<ApplicationStateAction>;
}>({
    state: initialState,
    dispatch: () => null
});

export default function ApplicationStateProvider(props: {
    children: JSX.Element;
}) {
    const [state, dispatch] = useReducer(applicationStateReducer, initialState);

    return (
        <ApplicationStateContext.Provider value={{ state, dispatch }}>
            {props.children}
        </ApplicationStateContext.Provider>
    );
}

export function useApplicationState(): {
    state: ApplicationState;
    dispatch: Dispatch<ApplicationStateAction>;
} {
    return useContext(ApplicationStateContext);
}
