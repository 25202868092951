import { Container, Row, Col, Card, Stack } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBrain,
    faKeyboard,
    faWandSparkles
} from "@fortawesome/free-solid-svg-icons";
import SearchWizard from "./SearchWizard";
import { Helmet } from "react-helmet";
import "./hero.css";
import { createRef, forwardRef } from "react";
import { faSquareCheck } from "@fortawesome/free-solid-svg-icons";

export default function HeroMinIcons() {
    const wizRef = createRef<HTMLDivElement>();
    const SearchWizForwarded = forwardRef(SearchWizard);

    return (
        <>
            <Helmet>
                <title>Next Jobs | Find Your Dream Job with AI</title>
            </Helmet>
            <div
                className="hero-section"
                style={{
                    background:
                        "linear-gradient(rgb(255 255 255 / 90%) 45%, rgb(255 255 255 / 55%) 90%), url(grid-pattern.webp) center center / cover"
                }}
            >
                <Container>
                    <Row>
                        <Col lg={6}>
                            <a
                                href="https://www.producthunt.com/posts/next-jobs?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-next&#0045;jobs"
                                target="_blank"
                            >
                                <img
                                    src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=476974&theme=light"
                                    alt="Next&#0032;Jobs - AI&#0045;Match&#0032;Your&#0032;Dream&#0032;Job | Product Hunt"
                                    style={{ width: "250px", height: "54px" }}
                                    width="250"
                                    height="54"
                                    className="mb-3"
                                />
                            </a>
                            <h1 className="display-5">
                                Find Your Ideal Job That Aligns with Your
                                Experience, Personality, And Aspirations
                            </h1>
                            <p className="lead">
                                We use AI to match you with jobs that suit your
                                career credentials, personality, and ideal work
                                environment. Find your ideal job within seconds
                                with our smarter search system. This combines
                                your character, preferences, and aspirations
                                with your career credentials.
                            </p>
                        </Col>
                        <Col lg={6} className="mt-4 mt-lg-0">
                            <iframe
                                width="100%"
                                height="360"
                                src="https://www.youtube.com/embed/jdBoZrKOzwE"
                                title="Explanatory Video"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </Col>
                    </Row>
                    <SearchWizForwarded ref={wizRef} />
                </Container>
            </div>
            <Container className="position-relative" style={{ bottom: 45 }}>
                <Row className="gy-3" style={{ margin: "0 0 7rem 0" }}>
                    <Col md={4}>
                        <Card className="text-center shadow-sm h-100">
                            <Card.Body>
                                <FontAwesomeIcon
                                    icon={faBrain}
                                    size="3x"
                                    className="mb-3"
                                />
                                <Card.Title>
                                    Find Your Ideal Job With AI
                                </Card.Title>
                                <Card.Text>
                                    Our AI technology matches your profile with
                                    the best job opportunities.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card className="text-center shadow-sm h-100">
                            <Card.Body>
                                <FontAwesomeIcon
                                    icon={faKeyboard}
                                    size="3x"
                                    className="mb-3"
                                />
                                <Card.Title>Tell Us Your Ideal Job</Card.Title>
                                <Card.Text>
                                    Describe your ideal job in your own words
                                    and let our AI do the rest.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card className="text-center shadow-sm h-100">
                            <Card.Body>
                                <FontAwesomeIcon
                                    icon={faWandSparkles}
                                    size="3x"
                                    className="mb-3"
                                />
                                <Card.Title>
                                    Decide Faster With Summaries
                                </Card.Title>
                                <Card.Text>
                                    Receive short summaries to quickly decide if
                                    a job is right for you.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row
                    className="gy-3"
                    xs={1}
                    lg={2}
                    style={{ margin: "7rem 0" }}
                >
                    <Col
                        as={Stack}
                        style={{ flex: "1" }}
                        className="justify-content-center"
                        gap={4}
                    >
                        <h2 className="text-center display-6">
                            Your Job Experience Is Important. But So Is Your
                            Personality And Preferences.
                        </h2>
                        <p>
                            Ever felt like your personality gets overlooked in
                            the job search process? You might have the
                            credentials but feel better suited to a relaxed
                            working environment. Remote working. Holidays.
                            Pensions. Corporate or non-corporate. These all
                            matter when searching for the perfect job.
                        </p>
                        <p>
                            Our platform takes all of these into consideration
                            and finds job matches that align with your unique
                            preferences and lifestyle. Use our platform to take
                            the big next forward in your journey.
                        </p>
                    </Col>
                    <Col className="text-center">
                        <img
                            src="/professionals512.jpg"
                            alt="Personality and preferences matching"
                            className="img-fluid rounded-4 shadow"
                        />
                    </Col>
                </Row>
                <Row
                    className="gy-3"
                    xs={1}
                    lg={2}
                    style={{ margin: "7rem 0" }}
                >
                    <Col
                        xs={{ order: 2 }}
                        lg={{ order: 1 }}
                        className="text-center"
                    >
                        <img
                            src="/align.jpg"
                            alt="Aligning jobs with your experiences"
                            className="img-fluid rounded-4 shadow"
                        />
                    </Col>
                    <Col
                        as={Stack}
                        style={{ flex: "1" }}
                        className="justify-content-center"
                        gap={5}
                        xs={{ order: 1 }}
                        lg={{ order: 2 }}
                    >
                        <h2 className="text-center display-6">
                            We Will Find You a Job That Aligns Your Experience
                            With Your
                        </h2>
                        <Row>
                            <Col className="d-flex justify-content-center">
                                <ul className="fa-ul">
                                    <li>
                                        <FontAwesomeIcon
                                            icon={faSquareCheck}
                                            size="lg"
                                            style={{ top: "2px" }}
                                            className="text-success"
                                            listItem
                                        />
                                        Preferences
                                    </li>
                                    <li>
                                        <FontAwesomeIcon
                                            icon={faSquareCheck}
                                            size="lg"
                                            style={{ top: "2px" }}
                                            className="text-success"
                                            listItem
                                        />
                                        Ideal Work Environment
                                    </li>
                                    <li>
                                        <FontAwesomeIcon
                                            icon={faSquareCheck}
                                            size="lg"
                                            style={{ top: "2px" }}
                                            className="text-success"
                                            listItem
                                        />
                                        Personality
                                    </li>
                                    <li>
                                        <FontAwesomeIcon
                                            icon={faSquareCheck}
                                            size="lg"
                                            style={{ top: "2px" }}
                                            className="text-success"
                                            listItem
                                        />
                                        Career Goals
                                    </li>
                                    <li>
                                        <FontAwesomeIcon
                                            icon={faSquareCheck}
                                            size="lg"
                                            style={{ top: "2px" }}
                                            className="text-success"
                                            listItem
                                        />
                                        Skill Set
                                    </li>
                                </ul>
                            </Col>
                            <Col className="d-flex justify-content-center">
                                <ul className="fa-ul">
                                    <li>
                                        <FontAwesomeIcon
                                            icon={faSquareCheck}
                                            size="lg"
                                            style={{ top: "2px" }}
                                            className="text-success"
                                            listItem
                                        />
                                        Work-Life Balance
                                    </li>
                                    <li>
                                        <FontAwesomeIcon
                                            icon={faSquareCheck}
                                            size="lg"
                                            style={{ top: "2px" }}
                                            className="text-success"
                                            listItem
                                        />
                                        Remote Work Opportunities
                                    </li>
                                    <li>
                                        <FontAwesomeIcon
                                            icon={faSquareCheck}
                                            size="lg"
                                            style={{ top: "2px" }}
                                            className="text-success"
                                            listItem
                                        />
                                        Company Culture
                                    </li>
                                    <li>
                                        <FontAwesomeIcon
                                            icon={faSquareCheck}
                                            size="lg"
                                            style={{ top: "2px" }}
                                            className="text-success"
                                            listItem
                                        />
                                        Growth Potential
                                    </li>
                                    <li>
                                        <FontAwesomeIcon
                                            icon={faSquareCheck}
                                            size="lg"
                                            style={{ top: "2px" }}
                                            className="text-success"
                                            listItem
                                        />
                                        Salary Expectations
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h2 ref={wizRef} className="text-center display-6">
                            Start Your Search Here
                        </h2>
                        <p className="text-center">
                            Be as descriptive as you can. Talk to us about your
                            job preferences like you would with a friend.
                        </p>
                    </Col>
                </Row>
                <SearchWizForwarded ref={wizRef} />
            </Container>
        </>
    );
}
