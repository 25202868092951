import Badge from "react-bootstrap/esm/Badge";
import Button from "react-bootstrap/esm/Button";
import Card from "react-bootstrap/esm/Card";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Stack from "react-bootstrap/esm/Stack";
import Placeholder from "react-bootstrap/esm/Placeholder";
import { Job } from "./index.d";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding } from "@fortawesome/free-solid-svg-icons";
import {
    faBriefcase,
    faCalendarDays,
    faListCheck,
    faUsers,
    faLock
} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-bootstrap/esm/Modal";
import { useEffect, useRef, useState } from "react";
import { useLogEvent } from "./FirebaseProvider";
import { useNavigate } from "react-router-dom";

function RoleDetailsView(props: {
    role: string;
    similarity: number;
    company: string;
    compIndShort: string; // Payments enablement
    location: string;
    workType: string; // remote
    employment: string; // permament
    experience: string; // 4
    seniorityLevel: string; // Senior Level
    pay?: string; // 30000
    companySize: string; // 1001 - 5000 employees
    benefits: string[]; // ["Competitive salary", "Stock options", "Global induction"]
}) {
    return (
        <Stack className="gap-4">
            <Stack className="gap-1">
                <p className="m-0">
                    <span className="fw-semibold">{props.company}</span> (
                    {props.compIndShort})
                </p>
                <Row xs={1} xxl={2} direction="horizontal">
                    <Col>
                        <span>{props.location}</span>
                    </Col>
                    <Col>
                        <Row xs="auto" className="gx-1">
                            <Col>
                                {!["not specified", "n/a"].includes(
                                    props.workType.toLowerCase()
                                ) && (
                                    <Badge
                                        bg="primary"
                                        className="text-uppercase"
                                    >
                                        {props.workType}
                                    </Badge>
                                )}
                            </Col>
                            <Col>
                                <Badge bg="primary" className="text-uppercase">
                                    {props.employment}
                                </Badge>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <span className="text-secondary">
                    {props.experience} ∙ {props.seniorityLevel} ∙ Pay{" "}
                    {props.pay?.toLowerCase()}
                </span>
            </Stack>
            <Stack gap={1}>
                <Stack direction="horizontal" className="gap-2">
                    <FontAwesomeIcon icon={faUsers} />
                    <span>{props.companySize}</span>
                </Stack>
                <Row xs="auto" className="gx-1 gy-1">
                    {props.benefits.map((b, i) => (
                        <Col key={i}>
                            <Badge bg="success">{b}</Badge>
                        </Col>
                    ))}
                </Row>
            </Stack>
        </Stack>
    );
}

/**
 * This component is the RoleDetailsView, but containing Placeholders instead.
 * It contains the exact same structure and layout.
 * It include all the elements of the RoleDetailsView, but with placeholder content.
 */
function PlaceholderRoleDetailsView() {
    return (
        <Placeholder animation="glow">
            <Placeholder xs={6} />
            <Placeholder xs={8} className="mt-4 mb-1" />
            <Placeholder xs={10} />
            <Placeholder xs={10} />
            <Placeholder xs={12} className="mb-4 mt-2" />
            <Placeholder xs={10} />
            <Placeholder xs={12} />
        </Placeholder>
    );
}

function CompanyDetailsView(props: {
    compFocusShort: string;
    roleActivitiesShort: string;
    roleReqsShort: string;
    stackDirection: "horizontal" | "vertical";
    blur?: boolean;
}) {
    return (
        <Stack
            direction={props.stackDirection}
            gap={2}
            style={{
                ...(props.blur && { filter: "blur(6px)" })
            }}
        >
            <Stack direction="horizontal" gap={4}>
                <div
                    style={{ width: "30px", height: "30px" }}
                    className="text-center"
                >
                    <FontAwesomeIcon icon={faBuilding} className="fs-2" />
                </div>
                <p className="m-0">
                    <span className="fw-semibold">Company Focus:</span>{" "}
                    {props.compFocusShort}
                </p>
            </Stack>
            <Stack direction="horizontal" gap={4}>
                <div
                    style={{ width: "30px", height: "30px" }}
                    className="text-center"
                >
                    <FontAwesomeIcon
                        icon={faBriefcase}
                        className="fs-2 text-center"
                    />
                </div>
                <p className="m-0">
                    <span className="fw-semibold">Role:</span>{" "}
                    {props.roleActivitiesShort}
                </p>
            </Stack>
            <Stack direction="horizontal" gap={4}>
                <div
                    style={{ width: "30px", height: "30px" }}
                    className="text-center"
                >
                    <FontAwesomeIcon icon={faListCheck} className="fs-2" />
                </div>
                <p className="m-0">
                    <span className="fw-semibold">Requirements:</span>{" "}
                    {props.roleReqsShort}
                </p>
            </Stack>
        </Stack>
    );
}

/**
 * This component is the CompanyDetailsView, but containing Placeholders instead.
 * It contains the exact same structure and layout.
 * It include all the elements of the CompanyDetailsView, but with placeholder content.
 */
function PlaceholderCompanyDetailsView() {
    return (
        <Stack direction="vertical" className="gap-3 mt-3">
            <Stack direction="horizontal" gap={4}>
                <Placeholder animation="glow">
                    <Placeholder style={{ width: "30px", height: "30px" }} />
                </Placeholder>
                <Stack>
                    <Placeholder animation="glow">
                        <Placeholder xs={10} />
                        <Placeholder xs={12} />
                        <Placeholder xs={8} />
                    </Placeholder>
                </Stack>
            </Stack>
            <Stack direction="horizontal" gap={4}>
                <Placeholder animation="glow">
                    <Placeholder
                        style={{ width: "30px", height: "30px" }}
                        className="text-center"
                    />
                </Placeholder>
                <Stack>
                    <Placeholder animation="glow">
                        <Placeholder xs={10} />
                        <Placeholder xs={12} />
                        <Placeholder xs={8} />
                    </Placeholder>
                </Stack>
            </Stack>
            <Stack direction="horizontal" gap={4}>
                <Placeholder animation="glow">
                    <Placeholder
                        style={{ width: "30px", height: "30px" }}
                        className="text-center"
                    />
                </Placeholder>
                <Stack>
                    <Placeholder animation="glow">
                        <Placeholder xs={10} />
                        <Placeholder xs={12} />
                        <Placeholder xs={8} />
                    </Placeholder>
                </Stack>
            </Stack>
        </Stack>
    );
}

function PersonalizationView(props: {
    commonalities: string;
    positioning: string;
    blur?: boolean;
    blurDetails?: boolean;
}) {
    const navigate = useNavigate();
    return (
        <Row className="position-relative text-bg-secondary px-3 px-sm-4 py-4 mx-0 bg-success-subtle text-success-emphasis">
            <Col xs={12} md={6} className="px-0 pe-md-3">
                <h5>Why am I a good fit?</h5>
                <p>{props.commonalities}</p>
            </Col>
            <Col xs={12} md={6} className="px-0 ps-md-3">
                <h5>How can I best position myself for this job?</h5>
                <p>{props.positioning}</p>
            </Col>
            {props.blur && (
                <Stack
                    gap={2}
                    className="position-absolute top-0 start-0 w-100 h-100 align-items-center justify-content-center"
                    style={{
                        background: "hsl(0deg 0% 100% / 40%)",
                        backdropFilter: "blur(6px)"
                    }}
                >
                    <span className="fw-semibold">
                        Upgrade Your Subscription to:
                    </span>
                    <ul style={{ listStyleType: '"✅  "' }}>
                        {props.blurDetails && (
                            <>
                                <li>Access your best job matches</li>
                                <li>
                                    Receive quick job summaries that you can
                                    skim
                                </li>
                            </>
                        )}
                        <li>Discover what sets you apart for this role</li>
                        <li>
                            Learn how to position yourself perfectly in your
                            application
                        </li>
                        <li>
                            Understand how your unique abilities make you a
                            great fit for this job
                        </li>
                    </ul>
                    <Button
                        onClick={() => navigate("/pricing")}
                        variant="primary"
                        className="shadow-sm"
                    >
                        <FontAwesomeIcon icon={faLock} className="me-2" />
                        {props.blurDetails
                            ? "Upgrade for All Insights"
                            : "Upgrade for Personalized Tips"}
                    </Button>
                </Stack>
            )}
        </Row>
    );
}

/**
 * This component is the PersonalzationView, but containing Placeholders instead.
 * It contains the exact same structure and layout.
 * It include all the elements of the PersonalzationView, but with placeholder content.
 */
function PlaceholderPersonalizationView() {
    return (
        <Row className="text-bg-secondary px-3 px-sm-4 py-4 mx-0 bg-success-subtle text-success-emphasis">
            <Col xs={12} md={6} className="px-0 pe-md-3">
                <Placeholder as="h5" animation="glow" className="mb-3">
                    <Placeholder xs={8} />
                </Placeholder>
                <Placeholder as="span" animation="glow">
                    <Placeholder xs={10} />
                    <Placeholder xs={12} />
                    <Placeholder xs={11} />
                </Placeholder>
            </Col>
            <Col xs={12} md={6} className="px-0 ps-md-3">
                <Placeholder as="h5" animation="glow" className="mb-3">
                    <Placeholder xs={8} />
                </Placeholder>
                <Placeholder as="span" animation="glow">
                    <Placeholder xs={10} />
                    <Placeholder xs={12} />
                    <Placeholder xs={11} />
                </Placeholder>
            </Col>
        </Row>
    );
}

export function JobCard(props: {
    job: Job;
    blurDetails?: boolean;
    blurPers?: boolean;
    lockButtons?: boolean;
    blurAll?: boolean;
    position: number;
    searchId: string;
}) {
    const [showJobDesc, setShowJobDesc] = useState(false);
    const logEvent = useLogEvent();
    const navigate = useNavigate();

    const onHideJobDetails: OnHideJobDetailsType = button => {
        logEvent("job_details_close", {
            button,
            similarity: props.job.similarity,
            card_position: props.position,
            job_id: props.job.id,
            job_title: props.job.role
        });
    };

    const onApply: OnClickType = button => {
        logEvent("job_apply", {
            button,
            similarity: props.job.similarity,
            card_position: props.position,
            job_id: props.job.id,
            job_title: props.job.role,
            search_id: props.searchId
        });
    };

    const onShowMore = () => {
        logEvent("job_details_open", {
            similarity: props.job.similarity,
            card_position: props.position,
            job_id: props.job.id,
            job_title: props.job.role,
            search_id: props.searchId
        });
    };

    let similarityColor = "text-primary";
    let similarityEmoji = "❄️";
    let action = "not a match, skip";
    if (props.job.similarity > 0.4 && props.job.similarity <= 0.53) {
        similarityColor = "text-info";
        similarityEmoji = "🌊";
        action = "low chances, refine";
    } else if (props.job.similarity > 0.53 && props.job.similarity <= 0.6) {
        similarityColor = "text-warning";
        similarityEmoji = "☕";
        action = "almost there, refine slightly";
    } else if (props.job.similarity > 0.6) {
        similarityColor = "text-danger";
        similarityEmoji = "🔥";
        action = "hot match, apply now";
    }

    return (
        <>
            <Card className="shadow-sm position-relative">
                <Stack>
                    <Row className="pt-3 px-3 px-sm-4 mb-2 mb-xl-3 gy-3">
                        <Col
                            xs={{ span: 12, order: 2 }}
                            xl={{ span: 8, order: 1 }}
                            xxl={{ span: 7, order: 1 }}
                        >
                            <img
                                src={props.job.logoUrl}
                                alt="Company Logo"
                                style={{
                                    maxHeight: "100px",
                                    maxWidth: "100px"
                                }}
                            />
                        </Col>
                        <Col
                            xs={{ span: 12, order: 3 }}
                            xl={{ span: 8, order: 2 }}
                            xxl={{ span: 7, order: 2 }}
                        >
                            <h4 className="fw-semibold">{props.job.role}</h4>
                        </Col>
                        <Col
                            xs={{ span: 12, order: 1 }}
                            xl={{ span: 4, order: 3 }}
                            xxl={{ span: 5, order: 3 }}
                        >
                            <span className={"fw-semibold " + similarityColor}>
                                {similarityEmoji}{" "}
                                {props.job.similarity.toLocaleString(
                                    navigator.language,
                                    {
                                        style: "percent",
                                        maximumFractionDigits: 1
                                    }
                                )}{" "}
                                Similarity ({action})
                            </span>
                        </Col>
                    </Row>
                    <div className="position-relative">
                        <Row
                            xs={1}
                            md={2}
                            className="pb-4 px-3 px-sm-4 pb-5 pb-sm-4 g-5"
                        >
                            <Col>
                                <RoleDetailsView {...props.job} />
                            </Col>
                            <Col className="position-relative">
                                <CompanyDetailsView
                                    {...props.job}
                                    stackDirection="vertical"
                                    blur={props.blurDetails}
                                />
                                {props.blurDetails && (
                                    <div
                                        className="position-absolute top-0 start-0 w-100 h-100"
                                        style={{
                                            background:
                                                "hsl(0deg 0% 100% / 45%)"
                                        }}
                                    />
                                )}
                            </Col>
                        </Row>
                        <PersonalizationView
                            commonalities={props.job.commonalities}
                            positioning={props.job.positioning}
                            blur={props.blurPers}
                            blurDetails={props.blurDetails}
                        />
                    </div>
                </Stack>
                <Row className="p-3 px-sm-4 align-items-center gx-2">
                    <Col xs={12} sm={6} className="mb-2 my-sm-0">
                        <FontAwesomeIcon
                            icon={faCalendarDays}
                            className="fs-5 me-2"
                        />
                        {props.job.postedAt
                            ? new Date(props.job.postedAt).toLocaleDateString()
                            : "N/A"}
                    </Col>
                    <Col xs={12} sm={3} className="my-1 my-sm-0">
                        <Button
                            variant="outline-primary"
                            className="w-100"
                            onClick={() => {
                                if (props.lockButtons) {
                                    navigate("/pricing");
                                } else {
                                    onShowMore();
                                    setShowJobDesc(true);
                                }
                            }}
                        >
                            {props.lockButtons && (
                                <FontAwesomeIcon
                                    icon={faLock}
                                    className="me-2"
                                />
                            )}
                            {props.lockButtons && "Upgrade to "}Learn More
                        </Button>
                    </Col>
                    <Col xs={12} sm={3} className="my-1 my-sm-0">
                        <Button
                            className="w-100"
                            onClick={() => {
                                if (props.lockButtons) {
                                    navigate("/pricing");
                                } else {
                                    onApply("job_card");
                                    window.open(
                                        props.job.originalPostingUrl,
                                        "_blank"
                                    );
                                }
                            }}
                            target="_blank"
                        >
                            {props.lockButtons && (
                                <FontAwesomeIcon
                                    icon={faLock}
                                    className="me-2"
                                />
                            )}
                            {props.lockButtons && "Upgrade to "}Apply
                        </Button>
                    </Col>
                </Row>
                {props.blurAll && (
                    <Stack
                        gap={2}
                        className="text-white position-absolute align-items-center justify-content-center px-3"
                        style={{
                            backdropFilter: "blur(6px)",
                            backgroundColor: "hsl(0deg 0% 0% / 65%)",
                            height: "104%",
                            width: "102%",
                            top: "-2%",
                            left: "-1%"
                        }}
                    >
                        <h3 className="text-center">
                            Find Your Perfect Fit Today From Over 35,000 Jobs
                        </h3>
                        <ul style={{ listStyleType: '"✅  "' }}>
                            <li>
                                Get Quick Job Matches with Personalized
                                Summaries and Advice
                            </li>
                            <li>
                                Discover What Makes You Unique for This Role
                            </li>
                            <li>
                                Learn How to Position Yourself Perfectly in the
                                Application Process
                            </li>
                        </ul>
                        <Button
                            onClick={() => navigate("/pricing")}
                            variant="primary"
                            className="shadow-sm"
                        >
                            <FontAwesomeIcon icon={faLock} className="me-2" />
                            Upgrade to Access All Jobs
                        </Button>
                    </Stack>
                )}
            </Card>
            <JobDetailsModalView
                {...props.job}
                onHideJobDetails={button => {
                    setShowJobDesc(false);
                    onHideJobDetails(button);
                }}
                onClickApply={onApply}
                show={showJobDesc}
            />
        </>
    );
}

type OnHideJobDetailsType = (button: "cross" | "close") => void;
type OnClickType = (target: "details_modal" | "job_card") => void;

function JobDetailsModalView(props: {
    role: string;
    jobDescription: string;
    originalPostingUrl: string;
    show: boolean;
    onHideJobDetails: OnHideJobDetailsType;
    onClickApply: OnClickType;
}) {
    const descriptionRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!descriptionRef.current) {
            return;
        }
        const description = props.jobDescription
            .replace(/<h3>/g, "<h5>")
            .replace(/<h2>/g, "<h4>")
            .replace(/<h1>/g, "<h3>")
            .replace(/<\/li>,/g, "</li>");
        descriptionRef.current.innerHTML = description;
    }, [props.jobDescription, props.show]);

    return (
        <Modal
            size="lg"
            show={props.show}
            onHide={() => props.onHideJobDetails("cross")}
        >
            <Modal.Header closeButton>
                <Modal.Title>{props.role}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h3>Job Details</h3>
                <div ref={descriptionRef} />
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={() => props.onHideJobDetails("close")}
                >
                    Close
                </Button>
                <Button
                    variant="primary"
                    href={props.originalPostingUrl}
                    onClick={() => {
                        props.onClickApply("details_modal");
                    }}
                    target="_blank"
                    rel="noreferrer"
                >
                    Apply
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

/**
 * A placeholder for a job card.
 * This is based on the JobCardView component above.
 * It contains the exact same structure and layout.
 * It include all the elements of the JobCardView, but with placeholder content.
 */
export function PlaceholderJobCard() {
    return (
        <Card className="shadow-sm">
            <Stack>
                <Placeholder
                    as={Card.Title}
                    animation="glow"
                    className="pt-3 px-3 px-sm-4"
                >
                    <Placeholder xs={5} />
                </Placeholder>
                <Row
                    xs={1}
                    md={2}
                    className="pb-4 px-3 px-sm-4 pb-5 pb-sm-4 g-5"
                >
                    <Col>
                        <PlaceholderRoleDetailsView />
                    </Col>
                    <Col>
                        <PlaceholderCompanyDetailsView />
                    </Col>
                </Row>
                <PlaceholderPersonalizationView />
            </Stack>
            <Row className="p-3 px-sm-4 align-items-center gx-2">
                <Col xs={12} sm={6} className="mb-2 my-sm-0">
                    <Placeholder as={Card.Text} animation="glow">
                        <Placeholder xs={5} />
                    </Placeholder>
                </Col>
                <Col xs={12} sm={3} className="my-1 my-sm-0">
                    <Placeholder.Button xs={12} />
                </Col>
                <Col xs={12} sm={3} className="my-1 my-sm-0">
                    <Placeholder.Button xs={12} />
                </Col>
            </Row>
        </Card>
    );
}
