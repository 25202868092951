import { Link, LoaderFunction, useLoaderData } from "react-router-dom";
import {
    doc,
    DocumentReference,
    Firestore,
    getDoc,
    Timestamp
} from "firebase/firestore";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";

// in Firestore
interface UserDeletionData {
    userId: string;
    status: "successful" | "failed";
    date: Timestamp;
}

export const getDataDeletionLoader: (db: Firestore) => LoaderFunction =
    db =>
    async ({ params }) => {
        const { userId } = params;
        if (!userId) {
            return { errMsg: "No user ID specified" };
        }
        const deletedUser = doc(
            db,
            "deletedUsers",
            userId
        ) as DocumentReference<UserDeletionData, UserDeletionData>;
        const duSnap = await getDoc(deletedUser);
        const duData = duSnap.data();
        if (!duData) {
            return {
                errMsg: `No user deletion data found for user ID ${userId}.`
            };
        }
        return {...duData, userId };
    };

export default function DataDeletion() {
    const loaderData = useLoaderData() as UserDeletionData | { errMsg: string };

    return (
        <Container style={{ paddingTop: "8em", minHeight: "92vh" }}>
            <Helmet>
                <title>Data Deletion | Next Jobs</title>
            </Helmet>
            {"errMsg" in loaderData ? (
                <>
                    <h3>Error</h3>
                    <p>{loaderData.errMsg}</p>
                    <p>
                        Go back to the <Link to="/">home page</Link>.
                    </p>
                </>
            ) : (
                <>
                    <h3>Data Deletion</h3>
                    <p>
                        User data for user ID {loaderData.userId} has been deleted
                        on {loaderData.date.toDate().toLocaleString()}.
                    </p>
                </>
            )}
        </Container>
    );
}
