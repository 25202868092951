import { createContext, useContext, useEffect, useState, useMemo } from "react";
import { initializeApp } from "firebase/app";
import {
    Auth,
    getAuth,
    getIdTokenResult,
    IdTokenResult,
    onAuthStateChanged,
    User
} from "firebase/auth";
import { Analytics, getAnalytics, logEvent } from "firebase/analytics";
import { Firestore, getFirestore } from "firebase/firestore";

import * as firebaseConfig from "./firebase-config.json";
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const firestore = getFirestore(app);

const AnalyticsContext = createContext<Analytics | null>(null);
const AuthContext = createContext<Auth | null>(null);
const UserContext = createContext<User | null | undefined>(undefined);
const FirestoreContext = createContext<Firestore | null>(null);

export default function FirebaseProvider(props: { children: JSX.Element }) {
    // TODO If you get an error about initialising Firebase twice, initialise
    // the Firebase app here with a useMemo.
    const [user, setUser] = useState<User | null | undefined>(undefined);
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, setUser);
        return unsubscribe;
    }, []);

    return (
        <AnalyticsContext.Provider value={analytics}>
            <AuthContext.Provider value={auth}>
                <UserContext.Provider value={user}>
                    <FirestoreContext.Provider value={firestore}>
                        {props.children}
                    </FirestoreContext.Provider>
                </UserContext.Provider>
            </AuthContext.Provider>
        </AnalyticsContext.Provider>
    );
}

export function useFirestore() {
    const firestore = useContext(FirestoreContext);
    if (!firestore) {
        throw new Error("useFirestore called outside of FirestoreContext");
    }
    return firestore;
}

export function useCurrentUser() {
    // The null-check here doesn't make sense because the user is null if
    // they're not authenticated and undefined before the Auth component has
    // initialised
    return useContext(UserContext);
}

// Note: it's better to use this function also in the cases where the current
// user is needed. Having two functions doing this (and therefore two hooks)
// makes the whole app reload twice as many times as when having just one,
// because React treats each hook on its own.
export function useAuthIdTokenRes() {
    const user = useCurrentUser();
    const [authIdTokenRes, setIdTokenRes] = useState<
        IdTokenResult | null | undefined
    >(undefined);
    useEffect(() => {
        if (user === undefined) {
            return;
        }
        if (user === null) {
            setIdTokenRes(null);
            return;
        }
        getIdTokenResult(user).then(setIdTokenRes);
    }, [user]);
    return authIdTokenRes;
}

export function useAuth() {
    const auth = useContext(AuthContext);
    if (!auth) {
        throw new Error("useAuth called outside of AuthContext");
    }
    return auth;
}

/**
 * Returns a function logging an event to Analytics
 */
export function useLogEvent() {
    const analytics = useContext(AnalyticsContext);
    if (!analytics) {
        throw new Error("useLogEvent called outside of AnalyticsContext");
    }
    const logEventFunc = useMemo(
        () => (name: string, params: Object) =>
            logEvent(analytics, name, params),
        [analytics]
    );
    return logEventFunc;
}
