import Container from "react-bootstrap/esm/Container";
import Nav from "react-bootstrap/esm/Nav";
import Navbar from "react-bootstrap/esm/Navbar";
import Stack from "react-bootstrap/esm/Stack";
import { NavLink, useNavigate, useSubmit } from "react-router-dom";
import { useCurrentUser, useLogEvent } from "./FirebaseProvider";

export default function HeaderView() {
    const submit = useSubmit();
    const user = useCurrentUser();
    const navigate = useNavigate();
    const logEvent = useLogEvent();

    return (
        <Navbar
            style={{
                background: "hsl(0deg 0% 89.8% / 55%)",
                backdropFilter: "blur(6px)"
            }}
            fixed="top"
            className="shadow-sm"
        >
            <Container>
                <Stack
                    direction="horizontal"
                    className="justify-content-between w-100"
                >
                    <Stack
                        direction="horizontal"
                        gap={4}
                        className="align-items-center"
                    >
                        <Navbar.Brand as={NavLink} to="/">
                            <img
                                src="/logo256.png"
                                width="32"
                                height="32"
                                className="me-2"
                                alt=""
                            />
                            Next Jobs
                        </Navbar.Brand>
                        <Nav.Item>
                            <Nav.Link as={NavLink} to="/pricing">
                                Pricing
                            </Nav.Link>
                        </Nav.Item>
                    </Stack>
                    <Stack direction="horizontal" gap={4}>
                        {user && (
                            <Nav.Item>
                                <Nav.Link as={NavLink} to="/profile">
                                    Profile
                                </Nav.Link>
                            </Nav.Item>
                        )}
                        <Nav.Item>
                            <Nav.Link
                                className="text-center fs-6"
                                onClick={() => {
                                    if (user) {
                                        logEvent("logout", {});
                                        submit(null, {
                                            method: "post",
                                            action: "/logout"
                                        });
                                    } else {
                                        navigate("/login");
                                    }
                                }}
                            >
                                {user ? "Logout" : "Login"}
                            </Nav.Link>
                        </Nav.Item>
                    </Stack>
                </Stack>
            </Container>
        </Navbar>
    );
}
