import { getIdToken, User } from "firebase/auth";
import { useState } from "react";
import Container from "react-bootstrap/esm/Container";
import { Helmet } from "react-helmet";
import { Link, redirect, useLoaderData } from "react-router-dom";
import { LoaderFunction } from "react-router-dom";
import Config from "./config";
import { useLogEvent } from "./FirebaseProvider";

export const getSuccessLoader: (
    user: User | null | undefined
) => LoaderFunction =
    user =>
    async ({ request }) => {
        if (user === undefined) {
            return "loading";
        }
        if (user === null) {
            return redirect("/login");
        }
        const searchParams = new URL(request.url).searchParams;
        const plan = searchParams.get("plan");
        if (!plan) {
            return { errMsg: "No plan specified" };
        }
        try {
            await getIdToken(user, true); // force refresh
        } catch (e) {
            const err = e as Error;
            return { errMsg: err.message };
        }
        return { plan };
    };

export default function Success() {
    const loaderData = useLoaderData() as
        | "loading"
        | { errMsg: string }
        | { plan: "starter" | "pro" | "ultimate" };
    const logEvent = useLogEvent();
    const [loggedSuccess, setLoggedSuccess] = useState(false);

    const Head = () => {
        return (
            <Helmet>
                <title>Payment Result | Next Jobs</title>
            </Helmet>
        );
    };

    if (loaderData === "loading") {
        return (
            <Container style={{ paddingTop: "8em", minHeight: "93.5vh" }}>
                <Head />
                <p>Loading...</p>
            </Container>
        );
    }

    if ("errMsg" in loaderData) {
        return (
            <Container style={{ paddingTop: "8em", minHeight: "93.5vh" }}>
                <Head />
                <h1>Error</h1>
                <p>{loaderData.errMsg}</p>
                <p>
                    Go back to the <Link to="/">home page</Link>.
                </p>
            </Container>
        );
    }

    if ("plan" in loaderData && !loggedSuccess) {
        logEvent("purchase", {
            currency: "USD",
            value: Config.PRICE_VALUES[loaderData.plan],
            transaction_id: crypto.randomUUID(),
            items: [
                {
                    item_id: Config.PRICES[loaderData.plan],
                    item_name: loaderData.plan,
                    price: Config.PRICE_VALUES[loaderData.plan],
                    quantity: 1
                }
            ]
        });
        setLoggedSuccess(true);
    }

    return (
        <Container style={{ paddingTop: "8em", minHeight: "93.5vh" }}>
            <Head />
            <h3>Success!</h3>
            <p>
                Your account has been upgraded to the{" "}
                <b>
                    {loaderData.plan.at(0)?.toUpperCase() +
                        loaderData.plan.slice(1)}
                </b>{" "}
                plan.
            </p>
            <p>
                You can now enjoy <b>{Config.DAYS[loaderData.plan]} days</b> of
                premium features.
            </p>
            <p>It might take a few minutes for the changes to propagate.</p>
            <p>
                To check the premium plan status, go to your{" "}
                <Link to="/profile">profile page</Link>.
            </p>
            <p>
                Or start your job search on the <Link to="/">home page</Link>.
            </p>
        </Container>
    );
}
