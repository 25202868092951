const Config = {
    BACKEND_URL: "https://llamojobs-backend-zzggtoazzq-ew.a.run.app",
    //BACKEND_URL: "http://localhost:8080",
    LOADER_MESSAGES: [
        "Loading the most suitable jobs with AI ...",
        "Hold on, we're analyzing your profile to find the best matches ...",
        "Almost there. Comparing your profile to 35,000 jobs in real time ...",
        "We're still computing. Our AI servers are working at max capacity ...",
        "Just a moment. We're almost ready to show you the best matches ..."
    ],
    DAYS: {
        starter: 7,
        pro: 14,
        ultimate: 30
    },
    PRICES: {
        starter: "price_1PONcS07VrlZQcIGUrSQQdnG",
        pro: "price_1PONcP07VrlZQcIG0Otf87UT",
        ultimate: "price_1PONcL07VrlZQcIGETESx8lh"
    },
    PRICE_VALUES: {
        starter: 6.99,
        pro: 11.99,
        ultimate: 19.99
    },
    GOOGLE_OAUTH_CLIENT_ID:
        "148109458516-8s6fsl0bujkqftlpcvn3crtgfbm1bbbp.apps.googleusercontent.com",
    HOTJAR_SITE_ID: 5027020,
    HOTJAR_VERSION: 6
};

export default Config;
