import * as firebaseui from "firebaseui";
import { useAuth, useFirestore, useLogEvent } from "./FirebaseProvider";
import {
    EmailAuthProvider,
    FacebookAuthProvider,
    GoogleAuthProvider,
    TwitterAuthProvider,
    User,
    UserCredential
} from "firebase/auth";
import Container from "react-bootstrap/esm/Container";
import { useEffect, useState } from "react";
import "firebaseui/dist/firebaseui.css";
import Config from "./config";
import { LoaderFunction, useLoaderData, redirect } from "react-router-dom";
import { Helmet } from "react-helmet";

export const getLoginLoader: (
    user: User | null | undefined
) => LoaderFunction =
    user =>
    ({ request }) => {
        if (user === undefined) {
            return "loading";
        }
        if (user) {
            return redirect("/profile");
        }
        const searchParams = new URL(request.url).searchParams;
        const redirectUrl = searchParams.get("redirect");
        if (!redirectUrl) {
            return "";
        }
        return redirectUrl;
    };

export default function Login() {
    const auth = useAuth();
    const loaderData = useLoaderData() as "loading" | string;
    const db = useFirestore();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const logEvent = useLogEvent();

    useEffect(() => {
        if (loaderData === "loading") {
            return;
        }
        const ui =
            firebaseui.auth.AuthUI.getInstance() ||
            new firebaseui.auth.AuthUI(auth);
        if (!ui.isPendingRedirect()) {
            ui.reset();
        }
        ui.start("#firebaseui-auth-container", {
            callbacks: {
                uiShown: () => setLoading(false),
                signInFailure: err =>
                    setError(`${err.code}: ${err.message}, ${err.credential}`),
                signInSuccessWithAuthResult: (uc: UserCredential) => {
                    logEvent("login", {
                        method: uc.user.providerData[0].providerId
                    });
                    return true;
                }
            },
            signInSuccessUrl: `/${loaderData}`,
            signInFlow: "popup",
            signInOptions: [
                {
                    provider: GoogleAuthProvider.PROVIDER_ID,
                    clientId: Config.GOOGLE_OAUTH_CLIENT_ID
                },
                FacebookAuthProvider.PROVIDER_ID,
                TwitterAuthProvider.PROVIDER_ID,
                EmailAuthProvider.PROVIDER_ID
            ],
            credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO,
            privacyPolicyUrl: "/privacy",
            tosUrl: "/terms"
        });
    }, [db, auth, loaderData, logEvent]);

    return (
        <Container
            className="text-center"
            style={{ marginTop: "8rem", minHeight: "80vh" }}
        >
            <Helmet>
                <title>Log In or Sign Up | Next Jobs</title>
            </Helmet>
            {loading || loaderData === "loading" ? (
                <p>Loading...</p>
            ) : (
                <h3 className="mb-4">Sign Up or Log In</h3>
            )}
            <div id="firebaseui-auth-container" />
            {error && <p>{error}</p>}
            <div className="text-center text-secondary firebaseui-container firebaseui-page-provider-sign-in firebaseui-id-page-provider-sign-in">
                <span className="firebaseui-tos firebaseui-tospp-full-message">
                    <span className="fw-semibold">Facebook login note</span>: Meta is experiencing{" "}
                    <a className="firebaseui-link firebaseui-pp-link" href="https://www.reddit.com/r/facebook/comments/13dilhy/stuck_with_account_verification_bug_for_ad_account/">
                        a server fault
                    </a>{" "}
                    leading to the warning, but the login works fine.{" "}
                    <a className="firebaseui-link firebaseui-pp-link" href="https://www.reddit.com/r/FacebookAds/comments/15d7h6k/your_account_is_restricted_at_the_moment/">
                        Read more
                    </a>
                    .
                </span>
            </div>
        </Container>
    );
}
