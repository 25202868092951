import Container from "react-bootstrap/esm/Container";
import { Helmet } from "react-helmet";

export default function Terms() {
    return (
        <Container style={{ paddingTop: "8em" }}>
            <Helmet>
                <title key="title">Next Jobs | Terms and Conditions</title>
            </Helmet>
            <RawTerms />
        </Container>
    );
}

export function RawTerms() {
    return (
        <>
            <h3 className="mb-4">Next Jobs Terms and Conditions</h3>

            <h4>1. Introduction</h4>
            <p>
                Welcome to Next Jobs! These Terms and Conditions ("Terms")
                govern your use of our AI-powered job board service ("Service").
                By accessing or using the Service, you agree to be bound by
                these Terms.
            </p>

            <h4>2. Acceptance of Terms</h4>
            <p>
                By using the Service, you agree to these Terms. If you do not
                agree, please do not use the Service.
            </p>

            <h4>3. Description of Service</h4>
            <p>
                Our Service offers both free and premium features. Free users
                can view up to five job listings with limited details. Premium
                users can access all job listings and additional details for a
                specified duration based on the plan purchased.
            </p>

            <h4>Premium Plans:</h4>
            <ul>
                <li>
                    <strong>Starter Plan</strong>: 7 days access for $6.99
                </li>
                <li>
                    <strong>Pro Plan</strong>: 14 days access for $11.99
                </li>
                <li>
                    <strong>Ultimate Plan</strong>: 30 days access for $19.99
                </li>
            </ul>

            <h4>4. Payment and Billing</h4>
            <p>
                All payments are one-time and non-refundable. You can pay using
                common and standard payment methods such as credit cards, bank
                transfers, and other payment methods supported by Stripe in your
                geography. In case of payment failure, you will not have access
                to premium features.
            </p>

            <h4>5. Access and Usage</h4>
            <p>
                <strong>Duration</strong>: Access to premium features is granted
                for the duration specified in the plan.
            </p>
            <p>
                <strong>Termination</strong>: Access to premium features will
                end after the plan's duration. No further access will be
                provided unless another plan is purchased.
            </p>
            <p>
                <strong>User Responsibilities</strong>: You are responsible for
                maintaining the confidentiality of your account information.
            </p>

            <h4>6. Disclaimers and Limitation of Liability</h4>
            <p>
                <strong>No Warranties</strong>: The Service is provided "as is"
                without warranties of any kind.
            </p>
            <p>
                <strong>Limitation of Liability</strong>: Our liability is
                limited to the maximum extent permitted by law. We are not
                responsible for any indirect, incidental, or consequential
                damages arising from your use of the Service.
            </p>

            <h4>7. User Conduct and Obligations</h4>
            <p>
                You agree not to engage in any prohibited activities, including
                but not limited to:
            </p>
            <ul>
                <li>Violating any laws or regulations</li>
                <li>Infringing on the rights of others</li>
                <li>Disrupting the Service</li>
            </ul>

            <h4>8. Privacy and Data Protection</h4>
            <p>
                We collect and use your data in accordance with our Privacy
                Policy and the GDPR. You have the right to access, rectify, or
                delete your data as per applicable laws.
            </p>

            <h4>9. Governing Law and Jurisdiction</h4>
            <p>
                These Terms are governed by the laws of Spain and the European
                Union. Any disputes arising from these Terms will be resolved in
                the courts of Spain.
            </p>

            <h4>10. Changes to Terms</h4>
            <p>
                We reserve the right to modify these Terms at any time. We will
                notify you of any changes by updating the Terms on our website.
                We will not send email notifications of term changes to users
                with an active plan. The terms agreed upon at the time of
                purchase will remain in effect for the entire duration of the
                current premium plan. Any changes made to these Terms will apply
                only to new purchases made after the changes are published.
            </p>

            <h4>11. Contact Information</h4>
            <p>
                For support or inquiries, please contact us via email at{" "}
                <a href="mailto:contact@nextjobs.app">support@nextjobs.app</a>.
            </p>

            <h4>12. Customer Support Availability</h4>
            <p>
                Customer support is available via email at{" "}
                <a href="mailto:contact@nextjobs.app">support@nextjobs.app</a>.
                We strive to respond to all inquiries at the earliest time
                possible.
            </p>
        </>
    );
}
