import {
    Dispatch,
    FormEventHandler,
    SetStateAction,
    useEffect,
    useState,
    ChangeEventHandler,
    useRef
} from "react";
import { Job } from "./index.d";
import Config from "./config";
import Container from "react-bootstrap/esm/Container";
import { JobCard, PlaceholderJobCard } from "./JobCard";
import Stack from "react-bootstrap/esm/Stack";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Form from "react-bootstrap/esm/Form";
import Button from "react-bootstrap/esm/Button";
import { Card, FormControlProps, Placeholder, Spinner } from "react-bootstrap";
import {
    useAuthIdTokenRes,
    useFirestore,
    useLogEvent
} from "./FirebaseProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faMagicWandSparkles,
    faMagnifyingGlass
} from "@fortawesome/free-solid-svg-icons";
import {
    ActionFunction,
    LoaderFunction,
    redirect,
    //useNavigate,
    useRouteLoaderData,
    useSubmit
} from "react-router-dom";
import {
    addDoc,
    collection,
    CollectionReference,
    doc,
    DocumentReference,
    Firestore,
    getDoc,
    Timestamp,
    updateDoc
} from "firebase/firestore";
import { SavedSearchData, SearchData } from "./SearchWizard";
import SubscribeNavbar from "./SubscribeFooter";
import LocationDropdown from "./LocationDropdown";
import OverlayTrigger from "react-bootstrap/esm/OverlayTrigger";
import Tooltip from "react-bootstrap/esm/Tooltip";
import quickstarters from "./quickstarters";
import {
    TwitterShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    EmailShareButton,
    RedditShareButton,
    TelegramShareButton,
    WhatsappShareButton,
    XIcon,
    LinkedinIcon,
    EmailIcon,
    RedditIcon,
    TelegramIcon,
    WhatsappIcon,
    FacebookIcon,
    FacebookMessengerShareButton,
    FacebookMessengerIcon
} from "react-share";
import { Helmet } from "react-helmet";
import "./share-buttons.css";
import { faLightbulb } from "@fortawesome/free-regular-svg-icons";

interface QueryResponse {
    maxSimilarity: number;
    hiddenJobs: number;
    jobs: Job[];
}

declare global {
    interface Window {
        twttr: any;
        FB: any;
    }
}

export const getSearchResultsAction: (db: Firestore) => ActionFunction =
    db =>
    async ({ request }) => {
        const formData = await request.formData();
        const data = Object.fromEntries(formData) as SearchData;
        const searchRef = await addDoc(
            collection(db, "searches") as CollectionReference<
                SavedSearchData,
                SavedSearchData
            >,
            {
                ...data,
                searchedAt: Timestamp.now()
            }
        );
        return redirect(`/search/${searchRef.id}`);
    };

export const getSearchResultsLoader: (db: Firestore) => LoaderFunction =
    db =>
    async ({ params }) => {
        if (!params.searchId) {
            return "root";
        }
        const searchRef = doc(
            db,
            "searches",
            params.searchId
        ) as DocumentReference<SearchData, SearchData>;
        const searchSnap = await getDoc(searchRef);
        const searchData = searchSnap.data();
        if (!searchData) {
            throw new Error("Provided search ID not found in database");
        }
        return { searchId: params.searchId, searchData };
    };

export default function SearchResults() {
    const loaderData = useRouteLoaderData("search") as
        | "root"
        | { searchData: SearchData; searchId: string };
    const [loading, setLoading] = useState(true);
    const [jobs, setJobs] = useState<Job[]>([]);
    const [moreButtonLoading, setMoreButtonLoading] = useState(false);
    const [maxSimilarity, setMaxSimilarity] = useState(0);
    const [error, setError] = useState("");
    //const [hiddenJobs, setHiddenJobs] = useState(0);
    const [pageTitle, setPageTitle] = useState(
        "Personalised Search | Next Jobs"
    );
    const [roleTitle, setRoleTitle] = useState("");
    const logEvent = useLogEvent();
    const authIdTokenRes = useAuthIdTokenRes();
    const db = useFirestore();
    const shareBtnsRef = useRef<HTMLDivElement>(null);
    const shareUrl = `https://nextjobs.app/search/${loaderData === "root" ? "" : loaderData.searchId}`;

    useEffect(() => {
        setError("");
        // if no search ID was passed there's no need to load anything
        if (loaderData === "root") {
            return;
        }
        if (authIdTokenRes === undefined) {
            return;
        }
        // transform loaderData.searchData keys from  camelcase to snake case
        // when logging the event
        logEvent(
            "job_search",
            Object.fromEntries(
                Object.entries(loaderData.searchData).map(([key, value]) => [
                    key.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`),
                    value
                ])
            )
        );
        if (loaderData.searchData.talents) {
            fetch(`${Config.BACKEND_URL}/job-title`, {
                method: "POST",
                headers: {
                    "content-type": "text/plain"
                },
                body: loaderData.searchData.talents || ""
            })
                .then(response => response.text())
                .then(title => {
                    setPageTitle(`${title} | Next Jobs`);
                    setRoleTitle(title);
                });
        } else {
            setPageTitle("Personalised Search | Next Jobs");
        }
        const performSearch = async () => {
            try {
                const res = await fetch(`${Config.BACKEND_URL}/search`, {
                    method: "POST",
                    headers: {
                        "content-type": "application/json",
                        accept: "application/json",
                        ...(authIdTokenRes && {
                            Authorization: `Bearer ${authIdTokenRes.token}`
                        })
                    },
                    body: JSON.stringify(loaderData.searchData)
                });
                const data = (await res.json()) as QueryResponse;
                const jobsFound = data.jobs[0].count;
                const searchRef = doc(
                    db,
                    "searches",
                    loaderData.searchId
                ) as DocumentReference<SearchData, SearchData>;
                await updateDoc(searchRef, {
                    maxSimilarity: data.maxSimilarity,
                    ...(authIdTokenRes ? {} : { hiddenJobs: data.hiddenJobs }),
                    jobsFound
                });
                setJobs(data.jobs);
                setMaxSimilarity(data.maxSimilarity);
                //setHiddenJobs(data.hiddenJobs);
            } catch (e) {
                const err = e as Error;
                setError(err.message);
            }
            setLoading(false);
        };
        performSearch();
    }, [authIdTokenRes, loaderData, logEvent, db]);

    const fetchMoreJobs = async () => {
        if (loaderData === "root") {
            setError("No search ID provided.");
            return;
        }
        logEvent("load_more_jobs", {
            searchId: loaderData.searchId
        });
        setMoreButtonLoading(true);
        setError("");
        try {
            const res = await fetch(`${Config.BACKEND_URL}/search`, {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    accept: "application/json",
                    ...(authIdTokenRes && {
                        Authorization: `Bearer ${authIdTokenRes.token}`
                    })
                },
                body: JSON.stringify({
                    ...loaderData.searchData,
                    lastSimilarity: jobs[jobs.length - 1].similarity
                })
            });
            if (!res.ok) {
                const msg = await res.text();
                throw new Error(`Server response ${res.status}: ${msg}`);
            }
            const data = (await res.json()) as QueryResponse;
            setJobs(oldJobs => [...oldJobs, ...data.jobs]);
        } catch (e) {
            const err = e as Error;
            setError(err.message);
        }
        setMoreButtonLoading(false);
    };

    const logSocialEvent = (network: string) => {
        console.log("Sharing on", network);
        logEvent("share", {
            method: network,
            item_id: shareUrl
        });
    };

    return (
        <div>
            <Helmet>
                <title>{pageTitle}</title>
            </Helmet>
            <Container>
                <Stack gap={3}>
                    <Row style={{ marginTop: "7rem" }}>
                        <Col lg={8} as={Stack} gap={3} className="pe-0">
                            <span className="text-center display-4">
                                Jobs Matching Your Profile
                            </span>
                            <span className="text-center display-5 mb-5 fs-5">
                                {loaderData === "root" ? (
                                    <p>
                                        Enter your preferences to see jobs that
                                        match your profile.
                                    </p>
                                ) : loading ? (
                                    <>
                                        <Spinner size="sm" className="me-1" />
                                        <CyclingLoadingMessages />
                                    </>
                                ) : jobs.length > 0 ? (
                                    <>
                                        <b>
                                            Your top match is{" "}
                                            {maxSimilarity.toLocaleString(
                                                navigator.language,
                                                {
                                                    style: "percent",
                                                    maximumFractionDigits: 1
                                                }
                                            )}{" "}
                                            aligned with your profile!
                                        </b>
                                        <br />
                                        Keep refining your search in the text
                                        boxes to increase your score.
                                    </>
                                ) : (
                                    "No jobs found. Try removing some of the filters or adjusting your search."
                                )}
                            </span>
                        </Col>
                        {loaderData !== "root" &&
                            !loading &&
                            jobs.length > 0 && (
                                <Col lg={4}>
                                    <div className="text-primary-emphasis border border-info-subtle rounded-3 px-1 py-4 bg-info-subtle shadow-sm">
                                        <h3 className="text-center fw-light">
                                            How it works
                                            <FontAwesomeIcon
                                                icon={faLightbulb}
                                                className="ms-2"
                                            />
                                        </h3>
                                        <ul className="fw-light">
                                            <li>
                                                Keep refining your search to
                                                improve your score.
                                            </li>
                                            <li>
                                                Anything{" "}
                                                <span className="fw-normal">
                                                    below 40% is a bad match
                                                </span>
                                                , you should skip it.
                                            </li>
                                            <li>
                                                Aim for{" "}
                                                <span className="fw-normal">
                                                    60% job similarity
                                                </span>{" "}
                                                minimum.
                                            </li>
                                            <li>
                                                Around{" "}
                                                <span className="fw-normal">
                                                    70% and above is almost
                                                    perfect!
                                                </span>
                                            </li>
                                            <li>
                                                100% is technically impossible,
                                                unless you write exactly the job
                                                description, word by word.
                                            </li>
                                            <li>
                                                The more detail you provide, the
                                                more your score will increase.
                                            </li>
                                            <li>
                                                Be as descriptive as{" "}
                                                <span className="fw-normal">
                                                    humanly possible
                                                </span>{" "}
                                                in the search.
                                            </li>
                                        </ul>
                                    </div>
                                </Col>
                            )}
                    </Row>
                    <div>
                        <p className="mb-2 text-muted">
                            Share this search with a friend!
                        </p>
                        <Stack
                            ref={shareBtnsRef}
                            direction="horizontal"
                            gap={2}
                        >
                            <TwitterShareButton
                                url={shareUrl}
                                title={pageTitle}
                                hashtags={["genAI", "artificialintelligence"]}
                                related={["roberthangu"]}
                                className="share-btn"
                                beforeOnClick={() => logSocialEvent("twitter")}
                            >
                                <XIcon size={32} round />
                            </TwitterShareButton>
                            <FacebookShareButton
                                url={shareUrl}
                                className="share-btn"
                                beforeOnClick={() => logSocialEvent("facebook")}
                            >
                                <FacebookIcon size={32} round />
                            </FacebookShareButton>
                            <LinkedinShareButton
                                url={shareUrl}
                                title={pageTitle}
                                summary="Skip wasted hours searching. See the most relevant jobs fitting who you are with AI "
                                source="Next Jobs"
                                className="share-btn"
                                beforeOnClick={() => logSocialEvent("linkedin")}
                            >
                                <LinkedinIcon size={32} round />
                            </LinkedinShareButton>
                            <RedditShareButton
                                url={shareUrl}
                                title={pageTitle}
                                className="share-btn"
                                beforeOnClick={() => logSocialEvent("reddit")}
                            >
                                <RedditIcon size={32} round />
                            </RedditShareButton>
                            <FacebookMessengerShareButton
                                url={shareUrl}
                                appId="465441046068107"
                                className="share-btn"
                                beforeOnClick={() =>
                                    logSocialEvent("messenger")
                                }
                            >
                                <FacebookMessengerIcon size={32} round />
                            </FacebookMessengerShareButton>
                            <WhatsappShareButton
                                url={shareUrl}
                                title={pageTitle}
                                className="share-btn"
                                beforeOnClick={() => logSocialEvent("whatsapp")}
                            >
                                <WhatsappIcon size={32} round />
                            </WhatsappShareButton>
                            <TelegramShareButton
                                url={shareUrl}
                                title={pageTitle}
                                className="share-btn"
                                beforeOnClick={() => logSocialEvent("telegram")}
                            >
                                <TelegramIcon size={32} round />
                            </TelegramShareButton>
                            <EmailShareButton
                                url={shareUrl}
                                subject={pageTitle}
                                body={`Hey,

I found this cool job search tool that uses AI to match you straight with the best jobs, saving hours on manual searches.

I pre-populated a search for you, as I knew you were looking for ${roleTitle} positions.

Check it out!`}
                                className="share-btn"
                                beforeOnClick={() => logSocialEvent("email")}
                            >
                                <EmailIcon size={32} round />
                            </EmailShareButton>
                        </Stack>
                    </div>
                    <Row className="gy-4">
                        <Col md={12} lg={4} xxl={3}>
                            <Filters
                                setLoading={setLoading}
                                searchTerms={
                                    loaderData === "root"
                                        ? {}
                                        : loaderData.searchData
                                }
                            />
                        </Col>
                        <Col md={12} lg={8} xxl={9}>
                            {loaderData === "root" ? (
                                <p className="text-muted">Search not started</p>
                            ) : loading ? (
                                <Placeholder as="p" animation="glow">
                                    <Placeholder xs={2} />
                                </Placeholder>
                            ) : (
                                <p className="text-muted">
                                    {jobs.length > 0 ? jobs[0].count : 0} jobs
                                    found
                                </p>
                            )}
                            <hr />
                            <Stack gap={4}>
                                {loaderData === "root" ? (
                                    <p className="text-center mt-5">
                                        <FontAwesomeIcon
                                            icon={faMagnifyingGlass}
                                            className="mb-3"
                                            size="xl"
                                        />
                                        <br />
                                        Please enter your preferences to the
                                        left to find the best matching jobs
                                    </p>
                                ) : loading ? (
                                    [...Array(5)].map((_, i) => (
                                        <PlaceholderJobCard key={i} />
                                    ))
                                ) : jobs.length === 0 ? (
                                    <p className="text-center">
                                        No jobs found. Try removing some of the
                                        filters or adjusting your search.
                                    </p>
                                ) : (
                                    <>
                                        {jobs.map((job, i) => (
                                            <JobCard
                                                searchId={loaderData.searchId}
                                                key={i}
                                                position={i + 1}
                                                job={job}
                                            />
                                        ))}
                                        <Button
                                            onClick={fetchMoreJobs}
                                            disabled={moreButtonLoading}
                                        >
                                            {" "}
                                            {moreButtonLoading ? (
                                                <Spinner size="sm" />
                                            ) : (
                                                "Load More ..."
                                            )}
                                        </Button>
                                        <p className="text-danger">{error}</p>
                                    </>
                                )}
                            </Stack>
                        </Col>
                    </Row>
                </Stack>
            </Container>
            <SubscribeNavbar maxSimilarity={maxSimilarity}/>
        </div>
    );
}

//function NonpremiumCardsView(props: {
//    jobs: Job[];
//    maxSimilarity: number;
//    hiddenJobs: number;
//    searchId: string;
//}) {
//    const navigate = useNavigate();
//    return (
//        <>
//            {props.hiddenJobs > 0 && (
//                <Card
//                    key={2001}
//                    className="bg-primary-subtle border border-primary-subtle shadow-sm"
//                >
//                    <Card.Body className="d-flex flex-column flex-md-row justify-content-between align-items-center">
//                        <span className="me-2 mb-2">
//                            There are {props.hiddenJobs} premium jobs with a top
//                            similarity of{" "}
//                            <span className="fw-semibold fs-5">
//                                {props.maxSimilarity.toLocaleString(
//                                    navigator.language,
//                                    {
//                                        style: "percent",
//                                        maximumFractionDigits: 1
//                                    }
//                                )}
//                            </span>{" "}
//                            that are currently locked.
//                            <br />
//                            You ideal fit might be among them. Unlock these top
//                            matches and save weeks of searching!
//                        </span>
//                        <Button
//                            className="ms-2 mt-2"
//                            onClick={() => navigate("/pricing")}
//                        >
//                            Upgrade to Access Your Best Matches
//                        </Button>
//                    </Card.Body>
//                </Card>
//            )}
//            {props.jobs.length <= 2 &&
//                props.jobs.map((job, i) => (
//                    <JobCard searchId={props.searchId} key={i} job={job} />
//                ))}
//            {props.jobs.length >= 3 &&
//                [
//                    props.jobs
//                        .slice(0, 2)
//                        .map((job, i) => (
//                            <JobCard
//                                searchId={props.searchId}
//                                key={(i + 1) * 10}
//                                job={job}
//                            />
//                        )),
//                    <JobCard
//                        searchId={props.searchId}
//                        key={2002}
//                        job={props.jobs[2]}
//                        blurPers
//                    />,
//                    props.jobs.length >= 4 && (
//                        <JobCard
//                            searchId={props.searchId}
//                            key={1001}
//                            job={props.jobs[3]}
//                            blurPers
//                            blurDetails
//                            lockButtons
//                        />
//                    ),
//                    props.jobs.length >= 5 && [
//                        <JobCard
//                            searchId={props.searchId}
//                            key={1002}
//                            job={props.jobs[4]}
//                            blurPers
//                            blurDetails
//                            lockButtons
//                        />,
//                        <JobCard
//                            searchId={props.searchId}
//                            key={1003}
//                            job={props.jobs[4]}
//                            blurAll
//                        />
//                    ]
//                ].flat()}
//        </>
//    );
//}

function Filters(props: {
    setLoading: Dispatch<SetStateAction<boolean>>;
    searchTerms: SearchData;
}) {
    const [formChanged, setFormChanged] = useState(false);
    const submit = useSubmit();

    const submitHandler: FormEventHandler<HTMLFormElement> = async e => {
        e.preventDefault();
        props.setLoading(true);
        setFormChanged(false);
        submit(e.currentTarget, { method: "post", action: "/search" });
    };

    return (
        <Card className="shadow-sm position-sticky" style={{ top: "80px" }}>
            <Card.Body>
                <Card.Title className="text-center mb-4">
                    Refine Your Search Here
                </Card.Title>
                <Form
                    id="search_results_filters"
                    autoComplete="off"
                    onSubmit={submitHandler}
                    onChange={() => setFormChanged(true)}
                >
                    <Stack gap={3}>
                        <Form.Group>
                            <Form.Label>Location</Form.Label>
                            <LocationDropdown
                                type="text"
                                name="location"
                                defaultValue={props.searchTerms.location}
                                placeholder="e.g., New York, Berlin, Remote"
                                onChange={() => setFormChanged(true)}
                                deploymentComponent="search_results"
                            />
                        </Form.Group>
                        <Form.Group>
                            <FilterInputControl
                                label="Talents"
                                name="talents"
                                placeholder={quickstarters.talentsPlaceholder}
                                defaultValue={props.searchTerms.talents}
                                quickstarters={quickstarters.talents}
                            />
                        </Form.Group>
                        <Form.Group>
                            <FilterInputControl
                                label="Work Environment"
                                name="workEnv"
                                placeholder={quickstarters.workEnvPlaceholder}
                                defaultValue={props.searchTerms.workEnv}
                                quickstarters={quickstarters.workEnv}
                            />
                        </Form.Group>
                        <Form.Group>
                            <FilterInputControl
                                label="Industries"
                                name="industries"
                                placeholder={
                                    quickstarters.industriesPlaceholder
                                }
                                defaultValue={props.searchTerms.industries}
                                quickstarters={quickstarters.industries}
                            />
                        </Form.Group>
                        <Button type="submit" disabled={!formChanged}>
                            Update Preferences
                        </Button>
                    </Stack>
                </Form>
            </Card.Body>
        </Card>
    );
}

function CyclingLoadingMessages() {
    const [currentMessage, setCurrentMessage] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentMessage(cm => (cm + 1) % Config.LOADER_MESSAGES.length);
        }, 7000);
        return () => clearInterval(interval);
    }, []);

    return <span>{Config.LOADER_MESSAGES[currentMessage]}</span>;
}

function FilterInputControl(
    props: FormControlProps & {
        name: string;
        label: string;
        quickstarters: { display: string; content: string }[];
    }
) {
    const [contentValue, setContentValue] = useState(
        (props.defaultValue as string | undefined) || ""
    );
    const [quickstartSelected, setQuickstartSelected] = useState(false);
    const [aiImproveClicked, setAiImproveClicked] = useState(false);
    const [improvementLoading, setImprovementLoading] = useState(false);
    const [errMsg, setErrMsg] = useState("");
    const logEvent = useLogEvent();
    const propsCopy = { ...props };
    delete propsCopy.defaultValue;

    const controlChangeHandler: ChangeEventHandler<HTMLInputElement> = e => {
        if (quickstartSelected && contentValue.length > 0) {
            logEvent("quickstart_suggestion_edit", {
                name: props.name,
                prompt: contentValue
            });
        }
        if (aiImproveClicked) {
            logEvent("ai_improvement_edit", {
                name: props.name,
                prompt: contentValue
            });
        }
        setQuickstartSelected(false);
        setAiImproveClicked(false);
        setContentValue(e.target.value);
    };

    const quickstartsSelectHandler: ChangeEventHandler<
        HTMLSelectElement
    > = e => {
        logEvent("quickstart_select", {
            name: props.name,
            prompt: e.target.value
        });
        setQuickstartSelected(true);
        setAiImproveClicked(false);
        setContentValue(
            e.target.value === "Select example" ? "" : e.target.value
        );
    };

    const aiImprovedHandler = async () => {
        logEvent("ai_improve_click", {
            name: props.name,
            prompt: contentValue
        });
        setQuickstartSelected(false);
        setAiImproveClicked(true);
        setImprovementLoading(true);
        try {
            const refinementRes = await fetch(`${Config.BACKEND_URL}/refine`, {
                method: "POST",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify({
                    content: contentValue,
                    type: props.name
                })
            });
            if (!refinementRes.ok) {
                const msg = await refinementRes.text();
                throw new Error(msg || refinementRes.statusText);
            }
            const refinedContent = await refinementRes.text();
            if (refinedContent.length === 0) {
                throw new Error("No improvement found. Please try again.");
            }
            setContentValue(refinedContent);
        } catch (e) {
            const err = e as Error;
            setErrMsg(err.message);
            setTimeout(() => setErrMsg(""), 5000);
        }
        setImprovementLoading(false);
    };

    return (
        <>
            <Form.Label>{props.label}</Form.Label>
            <Form.Control
                {...propsCopy}
                as="textarea"
                rows={4}
                className="mb-2"
                value={contentValue}
                onChange={controlChangeHandler}
            />
            <Stack
                direction="horizontal"
                className="justify-content-between align-items-center"
            >
                <OverlayTrigger
                    overlay={
                        <Tooltip>
                            Improve text with AI
                            {contentValue.length === 0 && (
                                <span>
                                    <br />
                                    (start writing something)
                                </span>
                            )}
                        </Tooltip>
                    }
                    placement="bottom"
                    delay={{ show: 300, hide: 200 }}
                >
                    <div>
                        <Button
                            size="sm"
                            variant="success"
                            className="top-0 start-100 shadow-sm"
                            onClick={aiImprovedHandler}
                            disabled={
                                improvementLoading || contentValue.length === 0
                            }
                        >
                            {improvementLoading ? (
                                <Spinner size="sm" />
                            ) : (
                                <span>
                                    Improve
                                    <FontAwesomeIcon
                                        icon={faMagicWandSparkles}
                                        className="ms-2"
                                    />
                                </span>
                            )}
                        </Button>
                    </div>
                </OverlayTrigger>
                <Form.Select
                    onChange={quickstartsSelectHandler}
                    size="sm"
                    className="w-50"
                >
                    <option key="initial" value="Select example">
                        Need inspiration?
                    </option>
                    {props.quickstarters.map((qs, i) => (
                        <option key={i} value={qs.content}>
                            {qs.display}
                        </option>
                    ))}
                </Form.Select>
            </Stack>
            {errMsg && <p className="text-danger">{errMsg}</p>}
        </>
    );
}
