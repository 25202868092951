import { Container, Row, Col, Form, Button, Carousel } from 'react-bootstrap';

const Hero2: React.FC = () => {
  return (
    <Container fluid className="home-page" style={{  backgroundColor: '#f1f1f1', padding: '50px 0', paddingTop: "8rem"}}>
      <Container>
        <Row>
          <Col>
            <iframe
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/YOUR_VIDEO_ID"
              title="Explanatory Video"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            <Form className="mt-4">
              <Form.Group controlId="formLocation">
                <Form.Label>Enter Your Preferred Job Location</Form.Label>
                <Form.Control type="text" placeholder="e.g., New York, San Francisco, Remote" />
              </Form.Group>
              <Button variant="primary" type="submit" className="mt-3">
                Start Questionnaire
              </Button>
            </Form>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col>
            <Carousel>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="https://via.placeholder.com/800x400"
                  alt="AI Matching"
                />
                <Carousel.Caption>
                  <h3>AI Matching</h3>
                  <p>Our AI technology matches your profile with the best job opportunities.</p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="https://via.placeholder.com/800x400"
                  alt="Natural Language Input"
                />
                <Carousel.Caption>
                  <h3>Natural Language Input</h3>
                  <p>Describe your ideal job in your own words and let our AI do the rest.</p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="https://via.placeholder.com/800x400"
                  alt="Custom Recommendations"
                />
                <Carousel.Caption>
                  <h3>Custom Recommendations</h3>
                  <p>Get personalized job recommendations tailored to your preferences.</p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="https://via.placeholder.com/800x400"
                  alt="Easy Navigation"
                />
                <Carousel.Caption>
                  <h3>Easy Navigation</h3>
                  <p>Easily browse and apply for jobs with our user-friendly interface.</p>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Hero2;

