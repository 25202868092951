import { FormEventHandler, useState } from "react";
import Navbar from "react-bootstrap/esm/Navbar";
import Container from "react-bootstrap/esm/Container";
import Button from "react-bootstrap/esm/Button";
import Form from "react-bootstrap/esm/Form";
import Spinner from "react-bootstrap/esm/Spinner";
import { setDoc, doc, DocumentReference, collection } from "firebase/firestore";
import { useFirestore, useLogEvent } from "./FirebaseProvider";
import { useRouteLoaderData } from "react-router-dom";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import FeedbackPopup from "./FeedbackForm";
import Stack from "react-bootstrap/esm/Stack";

interface SubscribeData {
    searchId: string;
    email: string;
}

export default function SubscribeNavbar(props: { maxSimilarity: number }) {
    const searchData = useRouteLoaderData("search") as
        | "root"
        | { searchId: string };
    const db = useFirestore();
    const [formValidated, setFormValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [errMsg, setErrMsg] = useState("");
    const [display, setDisplay] = useState<string | null>(
        window.localStorage.getItem("display_subscribe")
    );
    const logEvent = useLogEvent();

    const submitHandler: FormEventHandler<HTMLFormElement> = async e => {
        e.preventDefault();
        setFormValidated(true);
        if (!e.currentTarget.checkValidity()) {
            return;
        }
        const formData = new FormData(e.currentTarget);
        const email = formData.get("email");
        if (!email) {
            return;
        }
        const searchId = searchData === "root" ? "" : searchData.searchId;
        logEvent("generate_lead", {
            lead_source: "subscribe_footer",
            max_similarity: props.maxSimilarity,
            search_id: searchId
        });
        const subscribeRef = doc(
            collection(db, "subscribers")
        ) as DocumentReference<SubscribeData, SubscribeData>;
        try {
            await setDoc(subscribeRef, { searchId, email });
            setSubmitSuccess(true);
            window.localStorage.setItem("display_subscribe", "false");
            setTimeout(() => {
                setDisplay("false");
            }, 3000);
        } catch (e) {
            const err = e as Error;
            setErrMsg(err.message);
            setLoading(false);
        }
        setLoading(false);
    };

    if (display === "false") {
        return null;
    }

    return (
        <div className="position-relative">
            <Navbar
                bg="primary-subtle"
                fixed="bottom"
                className="py-3 border-top border-secondary-subtle shadow"
            >
                <Container className="justify-content-center">
                    {submitSuccess ? (
                        <div className="text-success fw-semibold py-2">
                            Success!
                        </div>
                    ) : (
                        <Form
                            noValidate
                            validated={formValidated}
                            onSubmit={submitHandler}
                            className="w-100"
                        >
                            <Row xs={1} md={2}>
                                <Col className="d-flex justify-content-md-end justify-content-center align-items-center">
                                    <Form.Label className="fw-semibold m-0">
                                        🎉 Get news and product updates sent to
                                    </Form.Label>
                                </Col>
                                <Col className="d-flex justify-content-center justify-content-md-start align-items-center">
                                    <Form.Group className="me-2">
                                        <Form.Control
                                            type="email"
                                            name="email"
                                            placeholder="Type your email..."
                                            required
                                            className="me-2"
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid email.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Button type="submit" disabled={loading}>
                                        {loading ? (
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                            />
                                        ) : (
                                            "Subscribe"
                                        )}
                                    </Button>
                                    {errMsg && (
                                        <span className="text-danger ms-2">
                                            {errMsg}
                                        </span>
                                    )}
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Container>
            </Navbar>
            <Stack className="bg-body-secondary">
                <span className="text-center text-secondary py-4">
                    (c) 2024 Next Jobs
                </span>
            </Stack>
            <FeedbackPopup margin="me-4" style={{ marginBottom: "6rem" }} />
        </div>
    );
}
