import Container from "react-bootstrap/esm/Container";
import { Helmet } from "react-helmet";

export default function PrivacyPolicy() {
    return (
        <Container style={{ paddingTop: "8em" }}>
            <Helmet>
                <title key="title">Privacy Policy | Next Jobs</title>
            </Helmet>
            <RawPrivacyPolicy />
        </Container>
    );
}

export function RawPrivacyPolicy() {
    return (
        <>
            <h2 className="mb-4">Privacy Policy</h2>
            <h3>1. Introduction</h3>
            <p>
                Welcome to Next Jobs (“we”, “our”, “us”). This Privacy Policy
                explains how we collect, use, disclose, and safeguard your
                information when you visit our website{" "}
                <a href="https://nextjobs.app">https://nextjobs.app</a>,
                including any other media form, media channel, mobile website,
                or mobile application related or connected thereto
                (collectively, the “Site”). Please read this privacy policy
                carefully. If you do not agree with the terms of this privacy
                policy, please do not access the site.
            </p>

            <h3>2. Data Collection and Use</h3>
            <h4>Types of Data Collected:</h4>
            <ul>
                <li>
                    <strong>Personal Data:</strong> When you register for an
                    account, use our services, or make a purchase, we may
                    collect personal data such as your name, email address, and
                    payment information.
                </li>
                <li>
                    <strong>Non-Personal Data:</strong> We may collect
                    non-personal data such as IP address, browser type, and
                    browsing behavior.
                </li>
            </ul>

            <h4>Additional Data for Job Matching:</h4>
            <p>
                <strong>Detailed User Data:</strong> To provide the best job
                matches and user experience, you may choose to provide detailed
                information such as desired work location, talents, past
                experience, desired work environment, and preferred industry.
                For anonymous visitors, this data is stored anonymously. For
                logged-in users, this data is connected to their user profile
                and is identifiable.
            </p>

            <h4>Methods of Data Collection:</h4>
            <ul>
                <li>
                    <strong>Account Creation:</strong> Information collected
                    when you create an account or login through third-party
                    providers like Google, Facebook, Twitter, or Github.
                </li>
                <li>
                    <strong>Browsing:</strong> Data collected through cookies
                    and tracking technologies as you navigate the Site.
                </li>
            </ul>

            <h4>Purpose of Data Collection:</h4>
            <ul>
                <li>To personalize job recommendations</li>
                <li>To process transactions and manage your subscription</li>
                <li>To improve our services and user experience</li>
                <li>To communicate with you regarding updates and offers</li>
            </ul>

            <h3>3. Legal Basis for Processing Data</h3>
            <p>We process your data based on the following legal grounds:</p>
            <ul>
                <li>
                    <strong>Consent:</strong> When you provide explicit consent
                    for specific purposes.
                </li>
                <li>
                    <strong>Performance of a Contract:</strong> To provide and
                    manage our services as per the contract with you.
                </li>
                <li>
                    <strong>Legal Obligations:</strong> To comply with legal
                    requirements.
                </li>
                <li>
                    <strong>Legitimate Interests:</strong> To improve our
                    services and ensure the security of our Site.
                </li>
            </ul>

            <h3>4. User Rights under GDPR</h3>
            <p>As a user under GDPR, you have the following rights:</p>
            <ul>
                <li>
                    <strong>Right to Access:</strong> You can request access to
                    your personal data that we hold.
                </li>
                <li>
                    <strong>Right to Rectification:</strong> You can request
                    correction of inaccurate or incomplete data.
                </li>
                <li>
                    <strong>Right to Erasure:</strong> You can request deletion
                    of your personal data under certain conditions.
                </li>
                <li>
                    <strong>Right to Restrict Processing:</strong> You can
                    request restriction of processing of your data under certain
                    conditions.
                </li>
                <li>
                    <strong>Right to Data Portability:</strong> You can request
                    to receive your data in a structured, commonly used format.
                </li>
                <li>
                    <strong>Right to Object:</strong> You can object to the
                    processing of your data on grounds related to your
                    particular situation.
                </li>
                <li>
                    <strong>
                        Rights Related to Automated Decision-Making and
                        Profiling:
                    </strong>{" "}
                    You can request human intervention if automated
                    decision-making affects you significantly.
                </li>
            </ul>

            <h3>5. Data Sharing and Disclosure</h3>
            <h4>Third Parties:</h4>
            <p>
                We may share your data with service providers for the purposes
                of payment processing, data analysis, email delivery, hosting
                services, customer service, and marketing assistance. We ensure
                that these third parties have appropriate safeguards in place to
                protect your data.
            </p>

            <h4>Data Transfers:</h4>
            <p>
                If we transfer your data outside the EEA, we will ensure it is
                protected by appropriate safeguards, such as standard
                contractual clauses or binding corporate rules.
            </p>

            <h3>6. Data Retention</h3>
            <p>
                We retain your personal data only for as long as necessary to
                fulfill the purposes for which we collected it, including for
                the purposes of satisfying any legal, accounting, or reporting
                requirements. Retention periods vary depending on the type of
                data and the nature of our relationship with you.
            </p>

            <h3>7. Data Security</h3>
            <p>
                We implement a variety of security measures to maintain the
                safety of your personal information. These measures include
                encryption, firewalls, and secure socket layer technology.
            </p>

            <h3>8. Cookies and Tracking Technologies</h3>
            <h4>Types of Cookies Used:</h4>
            <ul>
                <li>
                    <strong>Essential Cookies:</strong> Necessary for the
                    operation of the Site.
                </li>
                <li>
                    <strong>Performance Cookies:</strong> Help us understand how
                    visitors interact with the Site by collecting and reporting
                    information anonymously.
                </li>
                <li>
                    <strong>Functionality Cookies:</strong> Allow the Site to
                    remember choices you make (such as your username) and
                    provide enhanced, more personal features.
                </li>
            </ul>

            <h4>User Control Over Cookies:</h4>
            <p>
                You can control and manage cookies through your browser
                settings. Note that disabling cookies may affect the
                functionality of the Site.
            </p>

            <h3>9. Children’s Privacy</h3>
            <p>
                Our Site is not intended for children under 16. We do not
                knowingly collect personal data from children under 16. If we
                become aware that we have collected personal data from a child
                under 16, we will take steps to delete such information.
            </p>

            <h3>10. Changes to the Privacy Policy</h3>
            <p>
                We may update this privacy policy from time to time. Any changes
                will be posted on this page. You are advised to review this
                privacy policy periodically for any changes. Changes to this
                privacy policy are effective when they are posted on this page.
                It is your responsibility to keep up-to-date with any changes by
                reviewing the policy before each interaction with the Site.
            </p>

            <h3>11. Contact Information</h3>
            <p>
                If you have any questions about this privacy policy, please
                contact us at:
            </p>
            <ul>
                <li>
                    Email:{" "}
                    <a href="mailto:contact@nextjobs.app">
                        contact@nextjobs.app
                    </a>
                </li>
                <li>
                    Address: Carrer del Comte d'Urgell 241, 6 1, 08036
                    Barcelona, Spain
                </li>
            </ul>

            <h3>12. Miscellaneous</h3>
            <h4>Jurisdiction and Applicable Law:</h4>
            <p>
                This privacy policy and the relationship between you and us
                shall be governed by the laws of Spain, and any disputes shall
                be subject to the jurisdiction of the courts of Spain.
            </p>

            <h4>Disclaimer of Warranties and Limitation of Liability:</h4>
            <p>
                We do not guarantee, represent, or warrant that your use of our
                service will be uninterrupted, timely, secure, or error-free. To
                the extent permitted by law, we shall not be liable for any
                indirect, incidental, punitive, or consequential damages arising
                out of or in connection with your use of the Site.
            </p>
        </>
    );
}
