import { User } from "firebase/auth";
import { doc, DocumentReference, Firestore, getDoc } from "firebase/firestore";
import Button from "react-bootstrap/esm/Button";
import Container from "react-bootstrap/esm/Container";
import {
    Form,
    Link,
    LoaderFunction,
    redirect,
    useActionData,
    useNavigate,
    useRouteLoaderData
} from "react-router-dom";
import BsForm from "react-bootstrap/esm/Form";
import Stack from "react-bootstrap/esm/Stack";
import { useState } from "react";
import { useAuthIdTokenRes, useCurrentUser } from "./FirebaseProvider";
import { Helmet } from "react-helmet";

export interface ProfileData {
    plan?: string;
}

export const getProfileLoader: (
    db: Firestore,
    user: User | null | undefined
) => LoaderFunction = (db, user) => async () => {
    if (user === undefined) {
        return "loading";
    }
    if (user === null) {
        return redirect("/login?redirect=profile");
    }
    const userRef = doc(db, "users", user.uid) as DocumentReference<
        ProfileData,
        ProfileData
    >;
    const userSnap = await getDoc(userRef);
    return { ...userSnap.data() } as ProfileData;
};

export function Profile() {
    const loaderData = useRouteLoaderData("profile") as ProfileData | "loading";
    const navigate = useNavigate();
    const authIdTokenRes = useAuthIdTokenRes();
    const user = useCurrentUser();

    if (loaderData === "loading" || !user) {
        return (
            <Container style={{ paddingTop: "8em" }}>
                <p>Loading...</p>
            </Container>
        );
    }

    const [providerId] = user.providerData[0].providerId.split(".");
    const providerIdCapitalized =
        providerId.charAt(0).toUpperCase() + providerId.slice(1);

    const validPremiumPlan =
        authIdTokenRes?.claims?.premiumEndDate &&
        authIdTokenRes.claims.premiumEndDate >= Date.now();

    return (
        <Container style={{ paddingTop: "8em", minHeight: "93.5vh" }}>
            <Helmet>
                <title>Profile | Next Jobs</title>
            </Helmet>
            <h3>Profile Details</h3>
            <Stack direction="horizontal" gap={3}>
                <p>
                    <strong>Name:</strong> <br />
                    <strong>Email:</strong> <br />
                    <strong>Logged in with:</strong> <br />
                </p>
                <p>
                    {user.displayName} <br />
                    {user.email} <br />
                    {providerIdCapitalized} <br />
                </p>
            </Stack>
            <h3 className="mt-4">Premium Plan Details</h3>
            {validPremiumPlan && loaderData.plan ? (
                <p>
                    Your{" "}
                    <b>
                        {loaderData.plan.at(0)?.toUpperCase() +
                            loaderData.plan.slice(1)}
                    </b>{" "}
                    plan ends on{" "}
                    <b>
                        {new Date(
                            authIdTokenRes.claims.premiumEndDate as number
                        ).toLocaleString()}
                    </b>
                    .
                </p>
            ) : (
                <p>You're not on a premium plan yet.</p>
            )}
            <p>
                Visit our <Link to="/pricing">pricing</Link> page to see all
                available plans.
            </p>
            <p className="text-success">Free premium access during beta testing</p>
            <Button
                variant="primary"
                onClick={() => navigate("/pricing")}
                disabled
            >
                Upgrade to Premium
            </Button>
        </Container>
    );
}

//export const getProfileEditAction: (
//    db: Firestore,
//    user: User | null | undefined
//) => ActionFunction =
//    (db, user) =>
//    async ({ request }) => {
//        if (!user) {
//            return { message: "Loading" };
//        }
//        const fd = await request.formData();
//        const displayName = fd.get("displayName") as string;
//        const email = fd.get("email") as string;
//        const userRef = doc(db, "users", user.uid) as DocumentReference<
//            ProfileData,
//            ProfileData
//        >;
//        try {
//            if (displayName) {
//                await updateDoc(userRef, { displayName });
//                //TODO update this in auth, too
//            }
//            if (email) {
//                await updateDoc(userRef, { email });
//                //TODO update in auth too
//            }
//        } catch (e) {
//            const err = e as Error;
//            console.error(err);
//            return { message: err.message };
//        }
//        return redirect("/profile");
//    };

export function EditProfile() {
    const loaderData = useRouteLoaderData("profile") as ProfileData | "loading";
    const navigate = useNavigate();
    const error = useActionData() as { message: string } | undefined;
    const [displayName, setDisplayName] = useState("");
    const [email, setEmail] = useState("");

    if (loaderData === "loading") {
        return (
            <Container style={{ paddingTop: "8em" }}>
                <p>Loading...</p>
            </Container>
        );
    }

    return (
        <Container style={{ paddingTop: "8em" }}>
            <h3>Change Profile</h3>
            <Form noValidate method="post">
                <Stack gap={3}>
                    <BsForm.Group>
                        <BsForm.Label>Name</BsForm.Label>
                        <BsForm.Control
                            type="text"
                            placeholder="Your Name"
                            value={displayName}
                            onChange={e => setDisplayName(e.target.value)}
                            name="displayName"
                        />
                    </BsForm.Group>
                    <BsForm.Group>
                        <BsForm.Label>Email</BsForm.Label>
                        <BsForm.Control
                            type="email"
                            placeholder="Your Email Address"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            name="email"
                        />
                    </BsForm.Group>
                    <p className="text-danger">{error?.message}</p>
                    <Stack
                        className="justify-content-start"
                        direction="horizontal"
                        gap={2}
                    >
                        <Button
                            variant="secondary"
                            type="button"
                            onClick={() => navigate("/profile")}
                        >
                            Cancel
                        </Button>
                        <Button variant="primary" type="submit">
                            Save
                        </Button>
                    </Stack>
                </Stack>
            </Form>
        </Container>
    );
}
