import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Card from "react-bootstrap/esm/Card";
import Button from "react-bootstrap/esm/Button";
import Accordion from "react-bootstrap/esm/Accordion";
import Badge from "react-bootstrap/esm/Badge";
import Stack from "react-bootstrap/esm/Stack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareCheck } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import Config from "./config";
import { useCurrentUser, useLogEvent } from "./FirebaseProvider";
import { Helmet } from "react-helmet";

export default function PricingPage() {
    const navigate = useNavigate();
    const logEvent = useLogEvent();
    const user = useCurrentUser();

    const Head = () => {
        return (
            <Helmet>
                <title>Pricing | Next Jobs</title>
            </Helmet>
        );
    };

    if (user === undefined) {
        return (
            <Container style={{ paddingTop: "8em" }}>
                <Head />
                <p>Loading...</p>
            </Container>
        );
    }

    return (
        <>
            <Head />
            <div
                style={{
                    paddingTop: "20vh",
                    paddingBottom: 80,
                    background:
                        "url(circles-center.webp) center center / cover rgb(238 238 238 / 55%)",
                    backgroundBlendMode: "overlay"
                }}
            >
                {/* Hero Section */}
                <Container>
                    <Row className="my-5 text-center">
                        <Col>
                            <h1 className="display-5">
                                Find Your Dream Job Today
                            </h1>
                            <p className="fw-light fs-5 w-75 mx-auto">
                                Unlock all 35,000+ job listings with our premium
                                plans. Save hours of search and get access to
                                the top 10 most similar jobs to your unique
                                candidate profile.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Container className="position-relative" style={{ bottom: "3rem" }}>
                {/* Plan Comparison Section */}
                <Card>
                    <Card.Body>
                        <Row className="gy-3">
                            <Col md={4} className="">
                                <Card className="h-100 bg-light border-light-subtle">
                                    <Card.Body
                                        as={Stack}
                                        className="justify-content-end"
                                    >
                                        <Card.Title className="mb-3">
                                            Starter Access
                                        </Card.Title>
                                        <p className="fs-3 fw-semibold text-decoration-line-through">
                                            $6.99 / 7 days
                                        </p>
                                        <p className="fs-6 text-success">Free access during beta testing</p>
                                        <Button
                                            variant="primary"
                                            className="shadow-sm w-100 fw-semibold"
                                            disabled
                                            onClick={() => {
                                                logEvent("buy_plan_click", {
                                                    plan: "starter",
                                                    logged_in: user !== null
                                                });
                                                navigate(
                                                    `/payment?plan=starter&price=${Config.PRICES.starter}`
                                                );
                                            }}
                                        >
                                            Upgrade Plan
                                        </Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={4}>
                                <Card
                                    border="primary"
                                    style={{
                                        borderWidth: "2px"
                                    }}
                                    className="shadow h-100 bg-light"
                                >
                                    <div className="w-100 bg-success-subtle py-2 ps-3 text-success fw-semibold rounded-top-1">
                                        Save 15%
                                    </div>
                                    <Card.Body>
                                        <Stack
                                            direction="horizontal"
                                            className="justify-content-between align-items-start flex-column flex-lg-row"
                                        >
                                            <Card.Title className="mb-3">
                                                Pro Access
                                            </Card.Title>
                                            <Badge
                                                pill
                                                className="fs-6 text-primary bg-info-subtle mb-2"
                                            >
                                                Most popular
                                            </Badge>
                                        </Stack>
                                        <p className="fs-3 fw-semibold text-decoration-line-through">
                                                $11.99 / 14 days
                                        </p>
                                        <p className="fs-6 text-success">Free access during beta testing</p>
                                        <Button
                                            variant="primary"
                                            className="shadow-sm w-100 fw-semibold"
                                            disabled
                                            onClick={() => {
                                                logEvent("buy_plan_click", {
                                                    plan: "pro",
                                                    logged_in: user !== null
                                                });
                                                navigate(
                                                    `/payment?plan=pro&price=${Config.PRICES.pro}`
                                                );
                                            }}
                                        >
                                            Upgrade Plan
                                        </Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={4}>
                                <Card className="h-100 bg-light border-light-subtle">
                                    <div className="w-100 bg-success-subtle py-2 ps-3 text-success fw-semibold rounded-top-1">
                                        Save 30%
                                    </div>
                                    <Card.Body>
                                        <Card.Title className="mb-3">
                                            Ultimate Access
                                        </Card.Title>
                                        <p className="fs-3 fw-semibold text-decoration-line-through">
                                                $19.99 / 30 days
                                        </p>
                                        <p className="fs-6 text-success">Free access during beta testing</p>
                                        <Button
                                            variant="primary"
                                            className="shadow-sm w-100 fw-semibold"
                                            disabled
                                            onClick={() => {
                                                logEvent("buy_plan_click", {
                                                    plan: "ultimate",
                                                    logged_in: user !== null
                                                });
                                                navigate(
                                                    `/payment?plan=ultimate&price=${Config.PRICES.ultimate}`
                                                );
                                            }}
                                        >
                                            Upgrade Plan
                                        </Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col as={Stack} className="mt-5 align-items-center">
                                <h4 className="mb-3 text-center">
                                    Enjoy a Range of Benefits With Next Jobs
                                </h4>
                                <ul className="fa-ul">
                                    <li>
                                        <FontAwesomeIcon
                                            icon={faSquareCheck}
                                            size="lg"
                                            style={{ top: "2px" }}
                                            className="text-success"
                                            listItem
                                        />
                                        Find your ideal job within minutes
                                    </li>
                                    <li>
                                        <FontAwesomeIcon
                                            icon={faSquareCheck}
                                            size="lg"
                                            style={{ top: "2px" }}
                                            className="text-success"
                                            listItem
                                        />
                                        Receive matches that consider your
                                        aspirations, preferences, and
                                        personality type
                                    </li>
                                    <li>
                                        <FontAwesomeIcon
                                            icon={faSquareCheck}
                                            size="lg"
                                            style={{ top: "2px" }}
                                            className="text-success"
                                            listItem
                                        />
                                        Access only jobs that truly align with
                                        your profile
                                    </li>
                                    <li>
                                        <FontAwesomeIcon
                                            icon={faSquareCheck}
                                            size="lg"
                                            style={{ top: "2px" }}
                                            className="text-success"
                                            listItem
                                        />
                                        Avoid sifting through endless job
                                        descriptions with smart summaries
                                    </li>
                                    <li>
                                        <FontAwesomeIcon
                                            icon={faSquareCheck}
                                            size="lg"
                                            style={{ top: "2px" }}
                                            className="text-success"
                                            listItem
                                        />
                                        Discover what makes you a good fit for
                                        the job
                                    </li>
                                    <li>
                                        <FontAwesomeIcon
                                            icon={faSquareCheck}
                                            size="lg"
                                            style={{ top: "2px" }}
                                            className="text-success"
                                            listItem
                                        />
                                        Receive personalized feedback on how to
                                        position yourself in the interview based
                                        on your profile
                                    </li>
                                    <li>
                                        <FontAwesomeIcon
                                            icon={faSquareCheck}
                                            size="lg"
                                            style={{ top: "2px" }}
                                            className="text-success"
                                            listItem
                                        />
                                        Use our AI agent to help you create the
                                        perfect profile that will make you stand
                                        out
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>

                {/* FAQ Section */}
                <Row className="py-5">
                    <Col>
                        <h2 className="text-center mb-4">
                            Frequently Asked Questions
                        </h2>
                        <Accordion
                            onSelect={e =>
                                e && logEvent("faq_click", { element: e })
                            }
                        >
                            <h4 className="mb-4">Payment and Subscription</h4>

                            <Accordion.Item eventKey="What is included in the premium plans?">
                                <Accordion.Header>
                                    What is included in the premium plans?
                                </Accordion.Header>
                                <Accordion.Body>
                                    Our premium plans offer unlimited access to
                                    all job listings, personalized job matches,
                                    bespoke interview advice, and comprehensive
                                    company insights.
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="How do the weekly, bi-weekly, and monthly plans differ, and which one is best for me?">
                                <Accordion.Header>
                                    How do the weekly, bi-weekly, and monthly
                                    plans differ, and which one is best for me?
                                </Accordion.Header>
                                <Accordion.Body>
                                    The plans differ in duration, giving you
                                    flexibility based on how long you need
                                    access to our premium features. If you’re
                                    actively job searching, a longer plan might
                                    be more beneficial. Choose the plan that
                                    best fits your current needs and job search
                                    intensity.
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="How do I upgrade to a premium plan?">
                                <Accordion.Header>
                                    How do I upgrade to a premium plan?
                                </Accordion.Header>
                                <Accordion.Body>
                                    Simply select your preferred plan by
                                    clicking the corresponding "Upgrade Plan"
                                    button. You'll be guided through the secure
                                    payment process.
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="Can I cancel my subscription and get a refund if I’m not satisfied?">
                                <Accordion.Header>
                                    Can I cancel my subscription and get a
                                    refund if I’m not satisfied?
                                </Accordion.Header>
                                <Accordion.Body>
                                    While we don’t offer refunds for one-off
                                    subscriptions, we are committed to ensuring
                                    your satisfaction. If you have any issues,
                                    please contact our support team, and we’ll
                                    do our best to assist you. To do this, click
                                    the green "Feedback and Help" button on the
                                    lower right of your screen.
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="Are there any additional costs or hidden fees?">
                                <Accordion.Header>
                                    Are there any additional costs or hidden
                                    fees?
                                </Accordion.Header>
                                <Accordion.Body>
                                    No, there are no hidden fees. The price you
                                    see is the price you pay for the
                                    subscription duration you choose plus any
                                    VAT that's applicable in your jurisdiction.
                                    All features are included in the
                                    subscription cost.
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="What happens if I don’t find any suitable jobs during my subscription period?">
                                <Accordion.Header>
                                    What happens if I don’t find any suitable
                                    jobs during my subscription period?
                                </Accordion.Header>
                                <Accordion.Body>
                                    While our AI strives to provide the best
                                    matches, we understand this concern. We
                                    encourage you to reach out to our support
                                    team if you don't find suitable jobs, and
                                    we’ll assist you in optimizing your profile
                                    to improve your results. To do this, click
                                    the green "Feedback and Help" button on the
                                    lower right of your screen.
                                </Accordion.Body>
                            </Accordion.Item>

                            <h4 className="my-4">Product and Features</h4>

                            <Accordion.Item eventKey="How is this different from other job boards that are free?">
                                <Accordion.Header>
                                    How is this different from other job boards
                                    that are free?
                                </Accordion.Header>
                                <Accordion.Body>
                                    Our AI-powered job board uses advanced
                                    technology to match jobs to your
                                    personality, experiences, and preferences,
                                    ensuring more accurate and relevant job
                                    suggestions. Unlike traditional job boards,
                                    you don't have to sift through endless
                                    listings; our AI does the hard work for you.
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="Why should I pay for a job board service when there are free alternatives available?">
                                <Accordion.Header>
                                    Why should I pay for a job board service
                                    when there are free alternatives available?
                                </Accordion.Header>
                                <Accordion.Body>
                                    By upgrading to our premium service, you get
                                    access to personalized job matches, detailed
                                    job insights, and a comprehensive fit
                                    analysis that shows why you’re a great match
                                    for each role. This significantly increases
                                    your chances of finding the perfect job
                                    quickly and is not offered by free job board
                                    services.
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="Can I see a preview or sample of how the job matches and extracted details will look before purchasing?">
                                <Accordion.Header>
                                    Can I see a preview or sample of how the job
                                    matches and extracted details will look
                                    before purchasing?
                                </Accordion.Header>
                                <Accordion.Body>
                                    Yes, we offer a limited preview of job
                                    matches and extracted details so you can see
                                    how the system works and the value it
                                    provides before committing to a premium
                                    subscription. Head over to the{" "}
                                    <Link to="/search" target="_blank">
                                        job search page
                                    </Link>{" "}
                                    and have a look around!
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="How does the platform handle multiple job applications? Will it help me organize and prioritize them?">
                                <Accordion.Header>
                                    How does the platform handle multiple job
                                    applications? Will it help me organize and
                                    prioritize them?
                                </Accordion.Header>
                                <Accordion.Body>
                                    We're just starting out and at the moment we
                                    don't provide a way for you to organize your
                                    job applications. When you click on the
                                    "Apply" button on a job result, you will be
                                    taken directly to the posting company's
                                    application page. If you would like to see
                                    such a functionality in the future you can
                                    reach out to us by simply clicking the green
                                    "Feedback and Help" button on the lower
                                    right of your screen. We're happy to read
                                    your opinion!
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="Is there a way to track the jobs I’ve applied to or bookmarked within the platform?">
                                <Accordion.Header>
                                    Is there a way to track the jobs I’ve
                                    applied to or bookmarked within the
                                    platform?
                                </Accordion.Header>
                                <Accordion.Body>
                                    We're just starting out and at the moment we
                                    don't provide a way for you to track or
                                    bookmark your job applications. When you
                                    click on the "Apply" button on a job result,
                                    you will be taken directly to the posting
                                    company's application page. If you would
                                    like to see such a functionality in the
                                    future you can reach out to us by simply
                                    clicking the green "Feedback and Help"
                                    button on the lower right of your screen.
                                    We're happy to read your opinion!
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="How does the AI determine the best job matches for me?">
                                <Accordion.Header>
                                    How does the AI determine the best job
                                    matches for me?
                                </Accordion.Header>
                                <Accordion.Body>
                                    Our AI analyzes your profile description,
                                    including your personality, experiences, and
                                    preferred work environment, and compares it
                                    with job descriptions to find the best
                                    matches. It uses a sophisticated search
                                    algorithm to ensure high relevance and
                                    accuracy.
                                </Accordion.Body>
                            </Accordion.Item>

                            <h4 className="my-4">Profile and Job Matches</h4>

                            <Accordion.Item eventKey="How can I maximize the effectiveness of my profile to get the best job matches?">
                                <Accordion.Header>
                                    How can I maximize the effectiveness of my
                                    profile to get the best job matches?
                                </Accordion.Header>
                                <Accordion.Body>
                                    To maximize effectiveness, provide as much
                                    detail as possible about your skills,
                                    experiences, and preferences in the{" "}
                                    <Link to="/search" target="_blank">
                                        job search
                                    </Link>
                                    .
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="How accurate are the job matches provided by the AI?">
                                <Accordion.Header>
                                    How accurate are the job matches provided by
                                    the AI?
                                </Accordion.Header>
                                <Accordion.Body>
                                    Our AI uses advanced algorithms and
                                    continuous learning to provide highly
                                    accurate job matches. The more detailed your
                                    search criteria you enter in the{" "}
                                    <Link to="/search" target="_blank">
                                        job search
                                    </Link>
                                    , the better the AI can tailor job
                                    recommendations to your unique skills and
                                    preferences.
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="How detailed does my profile need to be for the AI to provide accurate matches?">
                                <Accordion.Header>
                                    How detailed does my profile description
                                    need to be for the AI to provide accurate
                                    matches?
                                </Accordion.Header>
                                <Accordion.Body>
                                    The more detailed the information you
                                    provide in the{" "}
                                    <Link to="/search" target="_blank">
                                        job search
                                    </Link>
                                    , the better the AI can tailor job matches
                                    to your specific needs. We recommend
                                    providing comprehensive information about
                                    your experiences, skills, and preferences to
                                    achieve the best results.
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="What if I have unique skills or experiences that don’t fit traditional job descriptions?">
                                <Accordion.Header>
                                    What if I have unique skills or experiences
                                    that don’t fit traditional job descriptions?
                                </Accordion.Header>
                                <Accordion.Body>
                                    Our AI is designed to recognize and value
                                    unique skills and experiences. Be sure to
                                    describe these in your{" "}
                                    <Link to="/search" target="_blank">
                                        job search
                                    </Link>
                                    , and the AI will match you with roles that
                                    appreciate and utilize your unique
                                    strengths.
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="What if I don’t have a clear career path or preferred work environment? Will the AI still be able to find good matches for me?">
                                <Accordion.Header>
                                    What if I don’t have a clear career path or
                                    preferred work environment? Will the AI
                                    still be able to find good matches for me?
                                </Accordion.Header>
                                <Accordion.Body>
                                    Yes, our AI is designed to work with all
                                    types of profiles. Even if your career path
                                    is unclear, the AI will use the information
                                    you provide in the{" "}
                                    <Link to="/search" target="_blank">
                                        job search
                                    </Link>{" "}
                                    to suggest roles that align with your skills
                                    and interests.
                                </Accordion.Body>
                            </Accordion.Item>

                            <h4 className="my-4">Job Listings and Updates</h4>

                            <Accordion.Item eventKey="How do I know that the jobs listed are up-to-date and not already filled?">
                                <Accordion.Header>
                                    How do I know that the jobs listed are
                                    up-to-date and not already filled?
                                </Accordion.Header>
                                <Accordion.Body>
                                    Our system regularly updates job listings to
                                    ensure they are current. We work with
                                    employers and job boards to provide the most
                                    up-to-date job opportunities available.
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="What measures are in place to ensure the quality and relevance of the job listings?">
                                <Accordion.Header>
                                    What measures are in place to ensure the
                                    quality and relevance of the job listings?
                                </Accordion.Header>
                                <Accordion.Body>
                                    We work closely with employers and job
                                    boards to verify the quality and relevance
                                    of job listings. Our system filters out
                                    outdated or irrelevant listings to maintain
                                    a high standard of job opportunities.
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="Will the AI be able to help if I’m looking for remote or international jobs?">
                                <Accordion.Header>
                                    Will the AI be able to help if I’m looking
                                    for remote or international jobs?
                                </Accordion.Header>
                                <Accordion.Body>
                                    Yes, our AI includes remote and
                                    international job listings in its search.
                                    Simply specify your interest in remote or
                                    international work in the{" "}
                                    <Link to="/search" target="_blank">
                                        job search
                                    </Link>
                                    , and the AI will include these options in
                                    your matches.
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="What types of jobs and industries are covered on this job board?">
                                <Accordion.Header>
                                    What types of jobs and industries are
                                    covered on this job board?
                                </Accordion.Header>
                                <Accordion.Body>
                                    Our job board covers a wide range of
                                    industries and job types, from tech and
                                    finance to healthcare and creative roles. No
                                    matter your field, you’ll find relevant
                                    opportunities on our platform. Give it a try
                                    in the{" "}
                                    <Link to="/search" target="_blank">
                                        job search
                                    </Link>
                                    .
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="How often is the job database updated with new listings?">
                                <Accordion.Header>
                                    How often is the job database updated with
                                    new listings?
                                </Accordion.Header>
                                <Accordion.Body>
                                    Our job database is updated daily to ensure
                                    you have access to the latest job
                                    opportunities. We continuously add new
                                    listings to keep our platform fresh and
                                    relevant.
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="How can I be sure that the job recommendations are unbiased and not influenced by paid listings?">
                                <Accordion.Header>
                                    How can I be sure that the job
                                    recommendations are unbiased and not
                                    influenced by paid listings?
                                </Accordion.Header>
                                <Accordion.Body>
                                    Our job recommendations are based solely on
                                    the similarity between your profile and job
                                    descriptions. We do not allow paid listings
                                    to influence our AI’s recommendations,
                                    ensuring unbiased and fair results.
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="Are there any success stories or testimonials from other users who found jobs through this service?">
                                <Accordion.Header>
                                    Are there any success stories or
                                    testimonials from other users who found jobs
                                    through this service?
                                </Accordion.Header>
                                <Accordion.Body>
                                    As we are just starting out and testing the
                                    platform with our first users, we're still
                                    in the process of collecting them. Make sure
                                    to watch this space in the future for
                                    success stories!
                                </Accordion.Body>
                            </Accordion.Item>

                            <h4 className="my-4">Support and Data Privacy</h4>

                            <Accordion.Item eventKey="What if I find a job I’m interested in but need help with my application? Does the service offer any additional support?">
                                <Accordion.Header>
                                    What if I find a job I’m interested in but
                                    need help with my application? Does the
                                    service offer any additional support?
                                </Accordion.Header>
                                <Accordion.Body>
                                    While our primary focus is on providing
                                    accurate job matches, we offer tips on how
                                    to improve your application through example
                                    search inputs and a magic AI button that
                                    improves the text you entered. Additionally,
                                    our support team is available to provide
                                    guidance if needed. To contact them, click
                                    the green "Feedback and Help" button on the
                                    lower right of your screen.
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="What kind of support is available if I have trouble using the service or understanding the job matches?">
                                <Accordion.Header>
                                    What kind of support is available if I have
                                    trouble using the service or understanding
                                    the job matches?
                                </Accordion.Header>
                                <Accordion.Body>
                                    Our support team is available to help you
                                    with any questions or issues. You can reach
                                    out to us by clicking the green "Feedback
                                    and Help" button on the lower right of your
                                    screen. We’ll provide prompt assistance to
                                    ensure you get the most out of our service.
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="Is my personal information and job search data safe and secure?">
                                <Accordion.Header>
                                    Is my personal information and job search
                                    data safe and secure?
                                </Accordion.Header>
                                <Accordion.Body>
                                    Absolutely. We prioritize your privacy and
                                    use advanced security measures to protect
                                    your data. Your information is encrypted and
                                    stored securely, and we never share your
                                    details with third parties without your
                                    consent. For more information on how we use
                                    your data, please refer to our{" "}
                                    <Link to="/privacy" target="_blank">
                                        Privacy Policy
                                    </Link>
                                    .
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

//function Testimonials() {
//    return (
//        <Row className="py-5">
//            <Col>
//                <h2 className="text-center mb-4">Testimonials</h2>
//                <Row className="gy-3">
//                    <Col md={4}>
//                        <Card className="shadow-lg p-3 mb-5 bg-white rounded">
//                            <Card.Body>
//                                <Stack
//                                    direction="horizontal"
//                                    gap={3}
//                                    className="align-items-center"
//                                >
//                                    <img
//                                        src={`https://robohash.org/JohnDoe?size=200x200`}
//                                        alt="User 1"
//                                        className="rounded-circle"
//                                        width="100"
//                                    />
//                                    <div>
//                                        <Card.Title>
//                                            <h4 className="font-weight-bold">
//                                                John Doe
//                                            </h4>
//                                        </Card.Title>
//                                        <Card.Subtitle className="text-muted">
//                                            Software Developer
//                                        </Card.Subtitle>
//                                    </div>
//                                </Stack>
//                                <Card.Text className="mt-3">
//                                    <i>
//                                        "I've been a premium user for a month
//                                        now. I've already received two offers
//                                        that I would have never found otherwise.
//                                        Highly recommend!"
//                                    </i>
//                                </Card.Text>
//                            </Card.Body>
//                        </Card>
//                    </Col>
//                    <Col md={4}>
//                        <Card className="shadow-lg p-3 mb-5 bg-white rounded">
//                            <Card.Body>
//                                <Stack
//                                    direction="horizontal"
//                                    gap={3}
//                                    className="align-items-center"
//                                >
//                                    <img
//                                        src={`https://robohash.org/JaneSmith?size=200x200`}
//                                        alt="User 2"
//                                        className="rounded-circle"
//                                        width="100"
//                                    />
//                                    <div>
//                                        <Card.Title>
//                                            <h4 className="font-weight-bold">
//                                                Jane Smith
//                                            </h4>
//                                        </Card.Title>
//                                        <Card.Subtitle className="text-muted">
//                                            Data Scientist
//                                        </Card.Subtitle>
//                                    </div>
//                                </Stack>
//                                <Card.Text className="mt-3">
//                                    <i>
//                                        "I upgraded to the Ultimate plan after a
//                                        week of using the Starter plan. The
//                                        difference in quality is amazing! I'm
//                                        sticking with it."
//                                    </i>
//                                </Card.Text>
//                            </Card.Body>
//                        </Card>
//                    </Col>
//                    <Col md={4}>
//                        <Card className="shadow-lg p-3 mb-5 bg-white rounded">
//                            <Card.Body>
//                                <Stack
//                                    direction="horizontal"
//                                    gap={3}
//                                    className="align-items-center"
//                                >
//                                    <img
//                                        src={`https://robohash.org/MaryJohnson?size=200x200`}
//                                        alt="User 3"
//                                        className="rounded-circle"
//                                        width="100"
//                                    />
//                                    <div>
//                                        <Card.Title>
//                                            <h4 className="font-weight-bold">
//                                                Mary Johnson
//                                            </h4>
//                                        </Card.Title>
//                                        <Card.Subtitle className="text-muted">
//                                            UX Designer
//                                        </Card.Subtitle>
//                                    </div>
//                                </Stack>
//                                <Card.Text className="mt-3">
//                                    <i>
//                                        "The personalized job matches are spot
//                                        on! I've never had such an easy time
//                                        finding a job before."
//                                    </i>
//                                </Card.Text>
//                            </Card.Body>
//                        </Card>
//                    </Col>
//                </Row>
//            </Col>
//        </Row>
//    );
//}
