import { createRoot } from "react-dom/client";
import App from "./App";
import FirebaseProvider, {
    useAuth,
    useAuthIdTokenRes,
    useCurrentUser,
    useFirestore
} from "./FirebaseProvider";
import "bootstrap/dist/css/bootstrap.min.css";
import ErrorPage from "./ErrorPage";
import {
    createBrowserRouter,
    createRoutesFromElements,
    Outlet,
    redirect,
    Route,
    RouterProvider
} from "react-router-dom";
import SearchResults, {
    getSearchResultsAction,
    getSearchResultsLoader
} from "./SearchResults";
import ApplicationStateProvider from "./ApplicationStateProvider";
import HeroMinIcons from "./HeroMinIcons";
import Payment, { getPaymentAction, getPaymentLoader } from "./Payment";
import { signOut } from "firebase/auth";
import { getProfileLoader, Profile } from "./Profile";
import PricingPage from "./Pricing";
import Success, { getSuccessLoader } from "./Success";
import Login, { getLoginLoader } from "./Login";
import PrivacyPolicy from "./PrivacyPolicy";
import Terms from "./Terms";
import DataDeletion, { getDataDeletionLoader } from "./DataDeletion";
import Hotjar from "@hotjar/browser";
import Config from "./config";
import Hero1 from "./Hero1";
import Hero2 from "./Hero2";
import Hero3 from "./Hero3";

Hotjar.init(Config.HOTJAR_SITE_ID, Config.HOTJAR_VERSION);

function RouterFirestoreWrapper() {
    const auth = useAuth();
    const db = useFirestore();
    const user = useCurrentUser();
    const authIdTokenRes = useAuthIdTokenRes();

    const router = createBrowserRouter(
        createRoutesFromElements(
            <Route path="/" element={<App />} errorElement={<ErrorPage />}>
                <Route index element={<HeroMinIcons />} />
                <Route
                    id="search"
                    path="search"
                    loader={getSearchResultsLoader(db)}
                    action={getSearchResultsAction(db)}
                    element={<Outlet />}
                >
                    <Route index element={<SearchResults />} />
                    <Route path=":searchId" element={<SearchResults />} />
                </Route>
                <Route
                    path="login"
                    element={<Login />}
                    loader={getLoginLoader(user)}
                />
                <Route
                    path="logout"
                    action={async () => {
                        await signOut(auth);
                        return redirect("/");
                    }}
                />
                <Route
                    path="profile"
                    id="profile"
                    element={<Outlet />}
                    loader={getProfileLoader(db, user)}
                >
                    <Route index element={<Profile />} />
                </Route>
                <Route
                    path="payment"
                    element={<Payment />}
                    action={getPaymentAction(authIdTokenRes)}
                    loader={getPaymentLoader(authIdTokenRes, db)}
                />
                <Route path="pricing" element={<PricingPage />} />
                <Route
                    path="success"
                    element={<Success />}
                    loader={getSuccessLoader(user)}
                />
                <Route path="privacy" element={<PrivacyPolicy />} />
                <Route path="terms" element={<Terms />} />
                <Route
                    path="data-deletion/:userId"
                    element={<DataDeletion />}
                    loader={getDataDeletionLoader(db)}
                />
                <Route path="/hero1" element={<Hero1 />} />
                <Route path="/hero2" element={<Hero2 />} />
                <Route path="/hero3" element={<Hero3 />} />
            </Route>
        )
    );
    return <RouterProvider router={router} />;
}

const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <FirebaseProvider>
        <ApplicationStateProvider>
            <RouterFirestoreWrapper />
        </ApplicationStateProvider>
    </FirebaseProvider>
);
